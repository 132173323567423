import { ChevronRightIcon } from "@heroicons/react/24/solid";

export default function YtbVideo() {
  return (
    <section className="relative z-10 overflow-hidden bg-white dark:bg-gray-900 py-16">
      <div className="container mx-auto">
        <div className="relative overflow-hidden">
          <div className="-mx-4 flex flex-wrap items-stretch">
            <div className="w-full px-4">
              <div className="mx-auto text-center max-w-5xl ">
                <h2 className="mb-5 text-3xl font-bold text-darkblue-100 dark:text-white md:text-[38px] md:leading-[1.44]">
                  <span>How can Elliott wave school benefit you</span>
                </h2>
                <div className='aspect-video' >
                  <iframe className='h-full w-full rounded-lg'
                    src="https://www.youtube.com/embed/x4HYI-Blc7w"
                    width="100%" title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                  </iframe>
                </div>
                <div className="w-full">
                  <a href="/memberplans" className="flex w-60 justify-center m-auto rounded-md border border-transparent bg-darkblue-100 p-3 mt-6 text-base font-medium text-white transition hover:bg-blue-800">
                    Start Your Trial Today
                    <ChevronRightIcon className="h-6 pt-0.5 ml-1 w-auto text-white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}