import axios from "axios"
import { DoneToast, ErrToast } from "../globalElements/AllToasts";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { X } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import { saveAs } from "file-saver"

export default function ExportEmails() {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');

  const downloadCsv = async () => {
    try {
      const response = await axios.get(`${localhost}/downloadcsv`, { responseType: 'blob' }, { headers: { 'Authorization': `Bearer ${token}` } });
      const blob = new Blob([response.data], { type: 'text/csv' });
      saveAs(blob, 'jointCustomers.csv');
      toast(<DoneToast text="Emails have been successfully exported!" />,
        {
          style: { background: 'none', boxShadow: 'none' },
          duration: 2000,
          position: 'top-center',
        });
        
    } catch (error) {
      toast(<ErrToast text="Error downloading the file!" />,
        {
          style: { background: 'none', boxShadow: 'none' },
          duration: 2000,
          position: 'top-center',
        });
    }
  };

  const confirmAction = () => {
    toast((t) => (
      <div className="relative p-4 text-center bg-white dark:bg-gray-900 text-gray-800 dark:text-white rounded-lg shadow sm:p-5">
        <button onClick={() => toast.dismiss(t.id)} type="button" className="text-gray-900 dark:text-white absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-300 dark:hover:bg-gray-700 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center">
          <X className="w-6 h-6" />
        </button>
        <QuestionMarkCircleIcon className="w-10 h-10 mb-1 mx-auto" />
        <p className="mb-3 text-xs dark:text-white text-gray-900">Are you sure?</p>
        <div className="flex justify-center items-center space-x-4">
          <button onClick={() => {
            downloadCsv();
            toast.dismiss(t.id);
          }} type="submit" className="py-2 px-3 text-xs font-medium text-center text-white bg-darkblue-100 rounded-lg hover:bg-darkblue-100">
            Yes, I'm sure
          </button>
          <button onClick={() => toast.dismiss(t.id)} type="button" className="py-2 px-3 text-xs font-medium border border-darkblue-100 bg-transparent text-darkblue-100 rounded-lg focus:z-10">
            No, cancel
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  return (
    <div onClick={confirmAction}>Export emails</div>
  )
}