import { useState } from "react"
import { Upload } from "react-bootstrap-icons";
import { useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-hot-toast";
import Select from 'react-select';
import { DoneToast, ErrToast } from "../../globalElements/AllToasts";
import { CaseArrows } from "../../globalElements/CaseArrows";

export default function AdmArticleCrypto() {
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [cryptoName, setCryptoName] = useState("");
  const [cryptoTime, setCryptoTime] = useState("");
  const [cryptoImage, setCryptoImage] = useState(null);
  const [cryptoTxt, setCryptoTxt] = useState("");
  const [cryptoCase, setCryptoCase] = useState("");

  const token = localStorage.getItem('token');

  const CreateCrypto = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("CryptoName", cryptoName);
    formData.append("CryptoTime", cryptoTime);
    formData.append("CryptoImage", cryptoImage);
    formData.append("CryptoText", cryptoTxt);
    formData.append("CryptoCase", cryptoCase);

    axios.post(`${localhost}/crypto`, formData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        toast(<DoneToast text="The Data added successfully" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        setTimeout(() => { navigate("/admin/crypto") }, 2000);
      })
      .catch((err) => {
        toast(<ErrToast text="There is a problem!" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        console.error('Error');
      });
  }

  const namesOptions = [
    {value:"BTC/USDT", label: "BTC/USDT"},
    {value:"ETH/USDT", label: "ETH/USDT"},
    {value:"BNB/USDT", label: "BNB/USDT"},
    {value:"LTC/USDT", label: "LTC/USDT"},
    {value:"DASH/USDT", label: "DASH/USDT"},
    {value:"XRP/USDT", label: "XRP/USDT"},
    {value:"BCH/USDT", label: "BCH/USDT"},
  ];

  const timeOptions = [
    {value: "4H", label: "4 Hours"},
    {value: "D1", label: "Day 1"},
    {value: "W", label: "Week"},
    {value: "M", label: "Month"},
  ];

  const casesOptions = [
    { value: "down", label: (<div className="flex">Down <CaseArrows caseProp="down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "down_up", label: (<div className="flex">Down up <CaseArrows caseProp="down_up" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "down_up_down", label: (<div className="flex">Down up down <CaseArrows caseProp="down_up_down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "horizontal", label: (<div className="flex">Horizontal <CaseArrows caseProp="horizontal" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "horizontal_down", label: (<div className="flex">Horizontal down <CaseArrows caseProp="horizontal_down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "horizontal_up", label: (<div className="flex">Horizontal up <CaseArrows caseProp="horizontal_up" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "up", label: (<div className="flex">Up <CaseArrows caseProp="up" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "up_down", label: (<div className="flex">Up down <CaseArrows caseProp="up_down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "up_down_up", label: (<div className="flex">Up down up <CaseArrows caseProp="up_down_up" classProp="ml-2 h-auto w-5" /></div>) },
  ];

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 pt-2 pb-4 mx-auto flex flex-wrap flex-col">

        <div className="">
          <form onSubmit={CreateCrypto}>
            <div className="xl:w-3/4 lg:w-2/3 md:w-3/4 w-3/4 block mx-auto mb-5 object-cover bg-blue-600 h-[34rem] object-center rounded" alt="hero">
              <label htmlFor="chartImage" className="flex justify-center items-center cursor-pointer w-full h-full">
                <div className="flex flex-col justify-center">
                  <Upload className="text-white h-10 lg:h-16 sm:h-12 w-auto" />
                  <p className="mt-3 text-white text-center text-xs sm:text-sm"><span className="font-semibold">Click to upload</span> the chart image</p>
                </div>
                <input id="chartImage" type="file" onChange={(e) => setCryptoImage(e.target.files[0])} className="hidden" accept="image/jpeg, image/jpg, image/png" />
              </label>
            </div>

            <div className="mx-auto xl:w-3/4 lg:w-2/3 md:w-3/4 w-3/4">
              {/* Select the crypto : */}
              <div className="mb-5">
              <div className="max-w-sm mb-3">
                  <label htmlFor="cryptos" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Currency</label>
                  <Select id="cryptos" value={namesOptions.find(option => option.value === cryptoName)} className="rounded-lg text-gray-800 bg-white"
                    onChange={(option) => setCryptoName(option.value)} options={namesOptions} isSearchable={false} />
                </div>
                <div className="max-w-sm mb-3">
                  <label htmlFor="times" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Time</label>
                  <Select id="times" value={timeOptions.find(option => option.value === cryptoTime)} className="rounded-lg text-gray-800 bg-white"
                    onChange={(option) => setCryptoTime(option.value)} options={timeOptions} isSearchable={false} />
                </div>
                <div className="max-w-sm mb-3">
                  <label htmlFor="arrows" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Charts</label>
                  <Select id="arrows" value={casesOptions.find(option => option.value === cryptoCase)} className="rounded-lg text-gray-800 bg-white"
                    onChange={(option) => setCryptoCase(option.value)} options={casesOptions} isSearchable={false} />
                </div>
              </div>

              <label htmlFor="times" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
              <textarea rows={5} type="text" value={cryptoTxt} onChange={(e) => setCryptoTxt(e.target.value)} placeholder="type here..." className="w-full leading-relaxed rounded-lg dark:text-gray-300 text-gray-700 text-base dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-600 dark:focus:border-blue-600"
                required />
              <div className="flex justify-end">
                <input type="submit" value="Submit" className="cursor-pointer mt-2 py-2 px-3 w-28 bg-blue-600 text-white rounded-lg" required />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}