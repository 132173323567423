import { format, parseISO } from 'date-fns';
import moment from 'moment';

export function FormatDate({isoDate}){
  const dateObject = parseISO(isoDate);
  const res = format(dateObject, 'dd MMM, yyyy');
  return `${res}`;
}

export function DateAgo({dateProp}){
  const res = moment(dateProp).fromNow();
  return `${res}`;
}