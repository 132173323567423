import { useEffect, useState } from "react";
import Sidebar from "./SideBar";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import axios from "axios";

export default function SubsNotifs() {
  const token = localStorage.getItem('token');
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_LOCALHOST}/notifbadge`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => setCount(res.data))
      .catch((err) => console.error('Error'));
  }, []);

  return (
    <>
      <button onClick={() => setSidebarOpen(true)} className='cursor-pointer mr-2 p-2 backdrop-blur-sm text-white bg-white/20 hover:bg-white/30 rounded-lg'>
        <BellAlertIcon className='h-6 w-auto text-white' />
        <div className="absolute inline-flex items-center justify-center w-[18px] h-[18px] text-[12px] font-bold text-white bg-red-600 rounded-full -top-2 -start-2 dark:border-gray-900">
          {count > 0?count:""}
        </div>
      </button>
      <Sidebar isOpen={isSidebarOpen} onClose={() => setSidebarOpen(false)} />
    </>
  )
}