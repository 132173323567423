import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { DateAgo } from '../../globalElements/FormatDate';

export default function Sidebar({ isOpen, onClose }) {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [dataNotifs, setDataNotifs] = useState([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [overlayImage, setOverlayImage] = useState('');

  useEffect(() => {
    axios.get(`${localhost}/notifs`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setDataNotifs(res.data);
      })
      .catch((err) => console.error('Error'));
  }, []);

  const handleImageClick = (image) => {
    setOverlayImage(`${process.env.REACT_APP_LOCALHOST_IMG}/${image}`);
    setIsOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  return (
    <>
      <div className={`fixed top-0 right-0 py-3 lg:w-[28rem] md:w-96 bg-darkwhite text-gray-800 dark:text-white dark:bg-gray-900 shadow-md h-full z-40 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className='px-3'>
          <p className='font-semibold text-lg mb-3'>Intraday updates</p>
          <button onClick={onClose} className="absolute top-0 right-0 lg:mb-2 lg:mx-2 lg:mt-3 m-4 text-gray-900 dark:text-white bg-transparent hover:bg-gray-300 dark:hover:bg-gray-700 hover:text-gray-900 rounded-lg p-1 inline-flex items-center">
            <XMarkIcon className="lg:h-5 lg:w-5 h-8 w-8 text-gray-800 dark:text-white" />
          </button>
        </div>
        
        <div className='h-[38rem] overflow-y-auto'>
          {dataNotifs.map((data, index) => (
            <div key={index} className='border-b p-4 border-darkblue-100 dark:border-darkyellow-100'>
              <div className='flex justify-between'>
                <p className="capitalize mb-1 text-xs border text-darkblue-100 border-darkblue-100 dark:text-darkyellow-100 dark:border-darkyellow-100 rounded-md w-12 text-center">
                  {data.type}
                </p>
                <p className="items-center text-sm">
                  <DateAgo dateProp={data.updated_at} />
                </p>
              </div>
              <p className="uppercase text-base text-darkblue-100 dark:text-darkyellow-100 font-semibold rounded-md">{data.NotifsName}</p>
              <p className="text-sm leading-normal my-2 flex-grow">{data.NotifsText}</p>
              <div>
                {data.NotifsImage && (
                  <img
                    src={`${process.env.REACT_APP_LOCALHOST_IMG}/${data.NotifsImage}`}
                    alt="updates"
                    onClick={() => handleImageClick(data.NotifsImage)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {isOverlayOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
        onContextMenu={(e) => e.preventDefault()}>
          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <img src={overlayImage} alt="Expanded view" style={{ maxHeight: '80vh', maxWidth: '80vw' }} />
            <button onClick={handleCloseOverlay} className="mt-4 bg-blue-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
