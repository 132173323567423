import React, { useState } from 'react';
import { Dropdown } from "flowbite-react";
import { Toaster } from 'react-hot-toast';
import { Link as ScrollLink } from "react-scroll";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import UserIcon from './UserIcon';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed ud-header left-0 top-0 z-40 flex w-full items-center bg-darkblue-100 backdrop-blur-md shadow-md">
      <Toaster />
      <div className="container">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <a href="/" className="navbar-logo block w-full py-5">
              <img src={`${process.env.PUBLIC_URL}/items/images/logo/logo.png`} className="h-14 w-auto header-logo" alt="logo" />
            </a>
          </div>
          <div className="flex items-center px-4">
            <div className='justify-center'>
              <button id="navbarToggler" className="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-darkblue-100 focus:ring-2 lg:hidden" onClick={toggleMenu}>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
              </button>
              <nav id="navbarCollapse" className={`absolute right-4 top-full ${isOpen ? "block" : "hidden"} w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:px-4 lg:py-0 lg:shadow-none dark:lg:bg-transparent xl:px-6`}>
                <ul className="block lg:flex 2xl:ml-20">
                  <li className="group relative">
                    <a href="/" className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-darkblue-100 dark:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
                      Home</a>
                  </li>
                  <li className="group relative">
                    <a href="/memberplans" className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-darkblue-100 dark:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
                      Member Plans</a>
                  </li>
                  <li className="group relative">
                    <Dropdown label="Resources" dismissOnClick={false} className="w-[180px]"
                      renderTrigger={() =>
                        <p className="ud-menu-scroll cursor-pointer mx-8 flex py-2 text-base font-medium text-dark group-hover:text-darkblue-100 dark:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
                          Resources
                          <ChevronDownIcon className="h-auto w-4 pt-1 ml-1 text-white" />
                        </p>}>
                      <ScrollLink to="OurServices" spy={true} smooth={true} duration={750}>
                        <Dropdown.Item>Services</Dropdown.Item>
                      </ScrollLink>
                      <ScrollLink to="OurBlog" spy={true} smooth={true} duration={750}>
                        <Dropdown.Item>Blog</Dropdown.Item>
                      </ScrollLink>
                      <ScrollLink to="JoinUs" spy={true} smooth={true} duration={750}>
                        <Dropdown.Item>Join Us</Dropdown.Item>
                      </ScrollLink>
                      <ScrollLink to="OurYoutubeChannel" spy={true} smooth={true} duration={750}>
                        <Dropdown.Item>Youtube Channel</Dropdown.Item>
                      </ScrollLink>
                      <ScrollLink to="OurSocialMedia" spy={true} smooth={true} duration={750}>
                        <Dropdown.Item>Social Media</Dropdown.Item>
                      </ScrollLink>
                      <ScrollLink to="Newsletter" spy={true} smooth={true} duration={750}>
                        <Dropdown.Item>Newsletter</Dropdown.Item>
                      </ScrollLink>
                    </Dropdown>
                  </li>
                  {/* <li className="group relative">
                    <a href="/aboutus" className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-darkblue-100 dark:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
                      About us</a>
                  </li> */}
                </ul>
              </nav>
            </div>
            <div className="flex items-center gap-2 lg:pr-0">
              <UserIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}