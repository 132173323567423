import { DateAgo } from "../../globalElements/FormatDate";
import DeleteBtn from "./DeleteBtn";

export default function OneNotif({ data }) {

  return (
    <div className="notiff rounded-lg p-4 bg-darkblue-100">
      <div>
        <p className="capitalize mb-1 text-xs border border-white text-white rounded-md w-12 text-center">{data.type}</p>
        <p className="uppercase text-base font-semibold text-white rounded-md">{data.NotifsName}</p>
      </div>
      <p className="text-sm leading-normal text-white my-2 flex-grow">{data.NotifsText}</p>
      <div>
        {data.NotifsImage?(
          <img src={`${process.env.REACT_APP_LOCALHOST_IMG}/${data.NotifsImage}`} alt="update image" />
        ):""}
      </div>
      <div className="flex mt-2 justify-between text-gray-200">
        <p className="items-center text-sm">
          <DateAgo dateProp={data.updated_at} />
        </p>
        <DeleteBtn notifID={data.NotifsID} />
      </div>
    </div>

  )
}