
export default function TimeLine() {
  return (
    <div className="bg-darkblue-100 py-12">
      <div className="w-4/5 mx-auto">
        <h2 className="mb-16 text-3xl font-bold text-center text-white md:text-[30px] md:leading-[1.44]">
          <span>Elliott wave school knowledge Built from experience</span>
        </h2>
        <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-white before:to-transparent">
          {/* <!-- Item #1 --> */}
          <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
            {/* <!-- Icon --> */}
            <div className="flex items-center justify-center w-10 h-10 rounded-full text-slate-500 group-[.is-active]:text-emerald-50 shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
              <span className="h-4 w-4 rounded-full bg-white"></span>
            </div>
            {/* <!-- Card --> */}
            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
              <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-darkblue-100">2016</div>
              </div>
              <div className="text-slate-500">Start educating and learning about the markets.</div>
            </div>
          </div>

          {/* <!-- Item #2 --> */}
          <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
            {/* <!-- Icon --> */}
            <div className="flex items-center justify-center w-10 h-10 rounded-full text-slate-500 group-[.is-active]:text-emerald-50 shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
              <span className="h-4 w-4 rounded-full bg-white"></span>
            </div>
            {/* <!-- Card --> */}
            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
              <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-darkblue-100">2018</div>
              </div>
              <div className="text-slate-500">Advanced research Of Technical Analysis, Elliott wave school In particular.</div>
            </div>
          </div>

          {/* <!-- Item #3 --> */}
          <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
            {/* <!-- Icon --> */}
            <div className="flex items-center justify-center w-10 h-10 rounded-full text-slate-500 group-[.is-active]:text-emerald-50 shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
              <span className="h-4 w-4 rounded-full bg-white"></span>
            </div>
            {/* <!-- Card --> */}
            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
              <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-darkblue-100">2020</div>
              </div>
              <div className="text-slate-500">Elliott wave school Analyst and publisher for Capital Forex Group Ltd.</div>
            </div>
          </div>

          {/* <!-- Item #4 --> */}
          <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
            {/* <!-- Icon --> */}
            <div className="flex items-center justify-center w-10 h-10 rounded-full text-slate-500 group-[.is-active]:text-emerald-50 shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
              <span className="h-4 w-4 rounded-full bg-white"></span>
            </div>
            {/* <!-- Card --> */}
            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
              <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-darkblue-100">2021</div>
              </div>
              <div className="text-slate-500">Analyst at TheLFB.com,  Forex portal.</div>
            </div>
          </div>

          {/* <!-- Item #5 --> */}
          <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group">
            {/* <!-- Icon --> */}
            <div className="flex items-center justify-center w-10 h-10 rounded-full text-slate-500 group-[.is-active]:text-emerald-50 shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
              <span className="h-4 w-4 rounded-full bg-white"></span>
            </div>
            {/* <!-- Card --> */}
            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
              <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-darkblue-100">2022</div>
              </div>
              <div className="text-slate-500">Beginning of Ew-forecast.com -Well known and trusted Elliott wave school service by more than 10k members across the globe.</div>
            </div>
          </div>

          {/* <!-- Item #3 --> */}
          <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
            {/* <!-- Icon --> */}
            <div className="flex items-center justify-center w-10 h-10 rounded-full text-slate-500 group-[.is-active]:text-emerald-50 shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
              <span className="h-4 w-4 rounded-full bg-white"></span>
            </div>
            {/* <!-- Card --> */}
            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
              <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-darkblue-100">2023</div>
              </div>
              <div className="text-slate-500">1st place FXStreet Technical Analysis Award 2016.</div>
            </div>
          </div>

          {/* <!-- Item #6 --> */}
          <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
            {/* <!-- Icon --> */}
            <div className="flex items-center justify-center w-10 h-10 rounded-full text-slate-500 group-[.is-active]:text-emerald-50 shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
              <span className="h-4 w-4 rounded-full bg-white"></span>
            </div>
            {/* <!-- Card --> */}
            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
              <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-darkblue-100">2024</div>
              </div>
              <div className="text-slate-500">Head of Elliott wave school market research at  ForexAnalytix.com 1st place FXStreet Best Buy Side Analysis Award.</div>
            </div>
          </div>
        </div>

        <div>
          <p className="mt-20 text-center text-[18px] font-bold text-white md:text-[20px] md:leading-[1.44]">
            Beginning of ewmtraders.com <br /> New Dimension of Elliott wave school Analysis</p>
          <a href="/memberplans" className="m-auto block w-32 mt-5 text-center rounded-md bg-white/[0.12] px-4 py-[14px] text-base font-medium text-white transition duration-300 ease-in-out hover:bg-white hover:text-dark">
            Join Now
          </a>
        </div>
      </div>
    </div>
  )
}