import "../assets/css/animate.css";
import "../assets/css/swiper-bundle.min.css";
import "../assets/css/tailwind.css";
import Channels from "./Channels";
import Footer from "../globalElements/Footer";
import Hero from "./Hero";
import JoinNow from "./JoinNow";

import Navbar from "../globalElements/Navbar";
import Newsletter from "./Newsletter";
import OurBlogs from "./OurBlogs";
import OurServices from "./OurServices";
import Part1 from "./Part1";
import TimeLine from "./TimeLine";
import YtbVideo from "./YtbVideo";
import YtbChannel from "./YtbChannel";

export default function HomePages(){
  return(
    <main>
      <Navbar/>
      <Hero/>
      <Part1/>
      <YtbVideo/>
      <TimeLine/>
      <OurServices/>
      <OurBlogs/>
      <JoinNow/>
      <YtbChannel/>
      <Channels/>
      <Newsletter/>
      <Footer/>
    </main>
  )
}