import React, { useState } from 'react';
import { useLocation } from 'react-router';
import UserIcon from '../globalElements/UserIcon';
import { Toaster } from 'react-hot-toast';

export default function AdmNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navItems = [
    { name: 'Intraday updates', path: '/admin/updates' },
    { name: 'Dashboard', path: '/admin/dashboard' },
    { name: 'Forex', path: '/admin/forex' },
    { name: 'Crypto', path: '/admin/crypto' },
    { name: 'Elliott wave school', path: '/subscriber/elliotwaveschool' },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="ud-header left-0 top-0 z-40 flex w-full items-center bg-blue-600 shadow-md">
      <Toaster/>
      <div className="container">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <a href="/" className="navbar-logo block w-full py-5">
              <img src={`${process.env.PUBLIC_URL}/items/images/logo/logo.png`} className="h-14 w-auto header-logo" alt="logo" />
            </a>
          </div>
          <div className="flex items-center px-4">
            <div className='justify-center'>
              <button id="navbarToggler" className="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden" onClick={toggleMenu}>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
              </button>
              <nav id="navbarCollapse" className={`absolute right-4 top-full ${isOpen ? "block" : "hidden"} w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:px-4 lg:py-0 lg:shadow-none dark:lg:bg-transparent xl:px-6`}>
                <ul className="block lg:flex 2xl:ml-20">
                  {
                    navItems.map((item, index) => (
                      <li key={index} className="group relative">
                        <a href={item.path} className={`ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 ${location.pathname === item.path ? 'border-b-2 border-white' : ''}`}>
                          {item.name}</a>
                      </li>
                    ))
                  }
                </ul>
              </nav>
            </div>
            <div className="flex items-center gap-2 lg:pr-0">
              <UserIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}