import { Youtube } from 'react-bootstrap-icons';

const CHANNEL_ID = 'UC2o3PJMtM5LOebvxS9DnPhA';

// ChannelProfile 
const ChannelProfile = () => (
  <div className="px-4 py-10">
    <img className="rounded-full w-24 mx-auto" src={`${process.env.PUBLIC_URL}/items/images/logo/ytb-logo.jpg`}
      alt="elliotwavemorocco" />
    <p className="text-center text-xl font-bold my-2">Elliot Wave Morocco</p>
    <div className='flex justify-center'>
      <a href={`https://www.youtube.com/channel/${CHANNEL_ID}`} target="_blank" rel="noopener noreferrer"
        className="inline-flex items-center py-1.5 px-2.5 bg-[#FF0000] hover:bg-red-600 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-white rounded-md transition duration-300">
        <Youtube className='w-auto h-6 fill-current mr-2' />
        <span>Subscribe</span>
      </a>
    </div>
  </div>
);

const YtbChannel = () => {
  const videos = [
    { id: "ltSYFCqR-OQ", title: "Ellio Wave Morocco / Elliott Wave Analysis GOLD All scenarios for this period" },
    { id: "gfJxjj9C9lY", title: "Elliott Wave Analysis GOLD / Elliot Wave Morocco" },
    { id: "U-PlZr0yVIk", title: "GOLD ELLIOT WAVE ANALYSIS | ELLIOT WAVE MOROCCO | 15 Oct 2023" },
    { id: "yneFqoeFNB8", title: "USOIL UPDATE FOR 55 DAYS ELLIOT WAVE MOROCCO FORCASTS" },
    { id: "suHo9EOxfn0", title: "All forex pairs 23/07/2023" },
    { id: "SFRhdQ9uLxA", title: "Gold Analysis 23/07/2023" },
    { id: "Ff5NfcF1haA", title: "ALL PAIR ELLIOT WAVE ANALYSIS | ELLIOT WAVE MOROCCO | 12 JUN 2023" },
    { id: "cpaLU_Ch5TA", title: "ALL PAIR ELLIOT WAVE ANALYSIS | ELLIOT WAVE MOROCCO" },
  ];

  return (
    <div id="OurYoutubeChannel" className='bg-white text-gray-800 dark:bg-gray-900 dark:text-white'>
      <ChannelProfile />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:px-10 md:px-6 px-10 py-5 shadow-md">
        {videos.map((video) => (
          <div key={video.id}>
            <iframe className="w-full rounded" src={`https://www.youtube.com/embed/${video.id}`}
              title={video.title} frameBorder="0" allowFullScreen></iframe>
            <div className=" py-3">
              <a href={`https://www.youtube.com/embed/${video.id}`} className="text-base mb-2">{video.title}</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YtbChannel;