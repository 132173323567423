const initialState = {
  forexs: [],
  cryptos: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Forex :
    case "Add_Forex":
      return {
        ...state, forexs: [...state.forexs, action.payload]
      };

    case "Update_Forex":
      const forx = state.forexs.find((a) => a.ForexID === parseInt(action.payload.ForexID));
      if (forx) {
        forx.ForexName = action.payload.ForexName;
        forx.ForexTime = action.payload.ForexTime;
        forx.ForexImage = action.payload.ForexImage;
        forx.ForexText = action.payload.ForexText;
        forx.ForexCase = action.payload.ForexCase;
      }
      return state;

    case "Delete_Forex":
      return {
        ...state, forexs: [...state.forexs.filter((u) => u.ForexID !== parseInt(action.payload))]
      };

    // Crypto :
    case "Add_Crypto":
      return {
        ...state, cryptos: [...state.cryptos, action.payload]
      };

    case "Update_Crypto":
      const cryp = state.cryptos.find((a) => a.CryptoID === parseInt(action.payload.CryptoID));
      if (cryp) {
        cryp.CryptoName = action.payload.CryptoName;
        cryp.CryptoTime = action.payload.CryptoTime;
        cryp.CryptoImage = action.payload.CryptoImage;
        cryp.CryptoText = action.payload.CryptoText;
        cryp.CryptoCase = action.payload.CryptoCase;
      }
      return state;

    case "Delete_Crypto":
      return {
        ...state, cryptos: [...state.cryptos.filter((u) => u.CryptoID !== parseInt(action.payload))]
      };

    default:
      return state;
  }
};
export default reducer;