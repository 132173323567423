import { useState } from "react"
import axios from "axios";
import { toast } from 'react-hot-toast';
import { DoneToast } from '../globalElements/AllToasts';
import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'

export default function Newsletter() {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios.post(`${localhost}/jointcustomers`, { email }, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        toast(<DoneToast text="Thanks for joining! Stay tuned for our updates!" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        setEmail("");
      })
      .catch((err) => console.error('Error'));
  };

  return (
    <div id="Newsletter" className="relative isolate overflow-hidden bg-darkblue-100 py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Subscribe to our newsletter.</h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
            Join the pulse of the market with Elliot Wave Morocco. Subscribe for cutting-edge insights and forecasts in crypto and forex trading.
            </p>
            <form onSubmit={handleSubmit} className="mt-6 flex max-w-md gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input id="email-address" name="email" type="email" autoComplete="email" value={email} onChange={(e) => setEmail(e.target.value)}
                required className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-inset focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Enter your email" />
              <button type="submit" className="flex-none rounded-md bg-white/[0.12] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition hover:text-gray-800 hover:bg-white">
                Subscribe
              </button>
            </form>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white p-2 ring-1 ring-white/10">
                <CalendarDaysIcon className="h-6 w-6 text-darkblue-100" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">Weekly articles</dt>
              <dd className="mt-2 leading-7 text-sm text-gray-200">
              Dive into our weekly analysis where we decode complex market patterns, providing you with actionable advice to navigate the waves of crypto and forex.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white p-2 ring-1 ring-white/10">
                <HandRaisedIcon className="h-6 w-6 text-darkblue-100" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">No spam</dt>
              <dd className="mt-2 leading-7 text-sm text-gray-200">
              Your inbox is sacred. We pledge to only send valuable content — no spam, just exclusive updates and tips from Elliot Wave Morocco.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}
