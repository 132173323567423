
export default function Footer() {
  return (
    <footer className="p-6 bg-white sm:p-6 dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex flex-col items-center text-gray-800 dark:text-white">
              <img src={`${process.env.PUBLIC_URL}/items/images/logo/logo-dark.png`} alt="logo" className="mr-3 h-16 w-auto dark:hidden" />
                  <img src={`${process.env.PUBLIC_URL}/items/images/logo/logo.png`} alt="logo" className="mr-3 h-16 w-auto hidden dark:block" />
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="my-1">
                  <a href="https://t.me/elliotwavemorocco" className="hover:underline ">Telegram</a>
                </li>
                <li className="my-1">
                  <a href="https://youtube.com/@elliotwavemorocoo" className="hover:underline">Youtube</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-4 border-gray-300 sm:mx-auto dark:border-gray-700 lg:my-4" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {new Date().getFullYear()} Elliot wave morocco. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  )
}