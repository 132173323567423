import { UserCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { LoadingPage } from "../../globalElements/LoadingPage";
import { FormatDate } from "../../globalElements/FormatDate";
import AddSubscription from "./AddSubscription";
import DeleteUser from "./DeleteUser";

export default function UsersTable() {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [dataUsers, setDataUsers] = useState([]);
  const [notSubs, setNotSubs] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');

  useEffect(() => {
    // subscribed :
    axios.get(`${localhost}/users`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setDataUsers(res.data);
      })
      .catch((err) => console.error('Error'));

    // Not subscribed :
    axios.get(`${localhost}/notsubscribed`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setNotSubs(res.data);
        setisLoading(false);
      })
      .catch((err) => console.error('Error'));
  }, []);

  // Subscribed :
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  // Items per page
  const itemsPerPage = 5;
  const counterPages = dataUsers.length < itemsPerPage ? 1 : Math.floor(dataUsers.length / itemsPerPage);
  const pageCount = Math.ceil(dataUsers.length / itemsPerPage);
  // Slice data for current page
  const paginatedData = dataUsers.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Not Subscribed :
  const handlePageChange1 = (selectedPage1) => {
    setCurrentPage1(selectedPage1.selected);
  };
  // Items per page
  const counterPages1 = notSubs.length < itemsPerPage ? 1 : Math.floor(notSubs.length / itemsPerPage);
  const pageCount1 = Math.ceil(notSubs.length / itemsPerPage);
  // Slice data for current page
  const paginatedData1 = notSubs.slice(
    currentPage1 * itemsPerPage,
    (currentPage1 + 1) * itemsPerPage
  );

  if (isLoading) { return <LoadingPage /> }
  return (
    <section className="bg-transparent text-gray-800 dark:text-white">
      <div className="dark:bg-gray-900 mb-4 rounded-lg border-[1px] border-gray-100 dark:border-gray-700 shadow-md">
        <h3 className="text-lg pt-3 text-gray-800 dark:text-white pl-6">Subscribed Users</h3>
        <div className="overflow-x-auto flex flex-col h-auto">
          <div className="w-full overflow-x-scroll md:overflow-auto max-w-xl xs:max-w-xl sm:max-w-xl md:max-w-7xl 2xl:max-w-none mt-1">
            <table className="table-auto overflow-scroll md:overflow-auto w-full text-left border-separate border-spacing-y-1">
              <tbody>
                {paginatedData.map((data, index) => {
                  return (
                    <tr key={index}
                      className="cursor-pointer border-t border-gray-100 dark:border-gray-700 hover:dark:bg-gray-700 hover:bg-blue-200 transition-colors duration-300 whitespace-nowrap flex px-5 justify-between items-center w-full h-[80px] text-[#637381] dark:text-gray-400 font-semibold text-sm">
                      <td className="flex gap-5">
                        <div className="w-8">
                          <UserCircleIcon className="h-8 w-8 text-blue-600" />
                        </div>
                        <div className="whitespace-nowrap min-w-[200px]">
                          <h1 className="text-black dark:text-white font-normal">
                            {data.name}
                          </h1>
                          <h2>{data.email}</h2>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-4">
                        {data.phone}
                      </td>
                      <td className="flex gap-1 whitespace-nowrap px-4">
                        <h3><FormatDate isoDate={data.created_at} /> </h3>
                      </td>
                      <td className="flex gap-1 px-4">
                        <AddSubscription userid={data.UserID} />
                      </td>
                      <td className="whitespace-nowrap px-4">
                        {data.myoffer === "both" ? "forex & crypto" : data.myoffer}
                      </td>
                      <td className="px-2">
                        <DeleteUser user_id={data.UserID} />
                      </td>
                      <td className="px-2 ">{data.price}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between px-5 items-end border-t border-gray-100 dark:border-gray-700 text-[#637381] py-3 text-sm">
            <div>1 to {counterPages} Of {counterPages} Entries</div>
            <ReactPaginate
              className="flex gap-3 items-center"
              previousLabel={<LeftArrow />}
              nextLabel={<RightArrow />}
              breakLabel={"..."}
              pageCount={pageCount}
              pageLinkClassName="w-5 h-5 text-center"
              pageClassName="hover:bg-gray-200 rounded-[2px] w-5 h-5 flex items-center justify-center"
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"dark:bg-gray-700 rounded-[2px] w-5 h-5 flex justify-center items-center border-gray-500 border"}
            />
          </div>
        </div>
      </div>

      <div className="dark:bg-gray-900 mt-4 rounded-lg border-[1px] border-gray-100 dark:border-gray-700 shadow-md">
        <h3 className="text-lg pt-3 text-gray-800 dark:text-white pl-6">Unsubscribed Users</h3>
        <div className="overflow-x-auto flex flex-col h-auto">
          <div className="w-full overflow-x-scroll md:overflow-auto max-w-xl xs:max-w-xl sm:max-w-xl md:max-w-7xl 2xl:max-w-none mt-1">
            <table className="table-auto overflow-scroll md:overflow-auto w-full text-left border-separate border-spacing-y-1">
              <tbody>
                {paginatedData1.map((data, index) => {
                  return (
                    <tr key={index}
                      className="cursor-pointer border-t border-gray-100 dark:border-gray-700 hover:dark:bg-gray-700 hover:bg-blue-200 transition-colors duration-300 whitespace-nowrap flex px-5 justify-between items-center w-full h-[80px] text-[#637381] dark:text-gray-400 font-semibold text-sm">
                      <td className="flex gap-5">
                        <div className="w-8">
                          <UserCircleIcon className="h-8 w-8 text-blue-600" />
                        </div>
                        <div className="whitespace-nowrap min-w-[200px]">
                          <h1 className="text-black dark:text-white font-normal">
                            {data.name}
                          </h1>
                          <h2>{data.email}</h2>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-4">
                        {data.phone}
                      </td>
                      <td className="flex gap-1 whitespace-nowrap px-4">
                        <h3><FormatDate isoDate={data.created_at} /> </h3>
                      </td>
                      <td className="flex gap-1 px-4">
                        <AddSubscription userid={data.UserID} />
                      </td>
                      <td className="px-2">
                        <DeleteUser user_id={data.UserID} />
                      </td>
                      <td className="px-2 ">{data.price}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between px-5 items-end border-t border-gray-100 dark:border-gray-700 text-[#637381] py-3 text-sm">
            <div>1 to {counterPages1} Of {counterPages1} Entries</div>
            <ReactPaginate
              className="flex gap-3 items-center"
              previousLabel={<LeftArrow />}
              nextLabel={<RightArrow />}
              breakLabel={"..."}
              pageCount={pageCount1}
              pageLinkClassName="w-5 h-5 text-center"
              pageClassName="hover:bg-gray-200 rounded-[2px] w-5 h-5 flex items-center justify-center"
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange1}
              containerClassName={"pagination"}
              activeClassName={"dark:bg-gray-700 rounded-[2px] w-5 h-5 flex justify-center items-center border-gray-500 border"}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const LeftArrow = () => {
  return <img src={`${process.env.PUBLIC_URL}/items/images/elements/leftarrow.svg`} />
};
const RightArrow = () => {
  return <img src={`${process.env.PUBLIC_URL}/items/images/elements/rightarrow.svg`} />
};