import React, { useEffect, useState } from "react";
import axios from 'axios';
import { LoadingPage1 } from "../../globalElements/LoadingPage";
import { CaseArrows } from "../../globalElements/CaseArrows";

import { DateAgo } from '../../globalElements/FormatDate'; // Addition


export default function SubsArticleCrypto({ currencyProp, trigger }) {
  const [allCrypto, setAllCrypto] = useState([]);
  const [cryptoTime, setCryptoTime] = useState('4H');
  const [isLoading, setIsLoading] = useState(true);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState('4H');
  const [highResImage, setHighResImage] = useState('');
  const [fourH, setFourH] = useState([]);
  const [oneD, setOneD] = useState([]);
  const [week, setWeek] = useState([]);
  const [month, setMonth] = useState([]);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');

  useEffect(() => {
    axios.post(`${localhost}/getcrypto/${cryptoTime}`,{"currency":currencyProp}, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setAllCrypto(res.data);
        axios.post(`${localhost}/getcryptochart`, {"currency":currencyProp}, { headers: { 'Authorization': `Bearer ${token}` } })
          .then((res) => {
            setFourH(res.data[0]);
            setOneD(res.data[1]);
            setWeek(res.data[2]);
            setMonth(res.data[3]);
          })
          .catch((err) => console.error('Error'));
        setIsLoading(false);
      })
      .catch((err) => console.error('Error'));
  }, [currencyProp, trigger, cryptoTime]);

  const handleClick = (Tm) => {
    setIsLoading(true);
    setCryptoTime(Tm);
    axios.post(`${localhost}/getcrypto/${Tm}`,{"currency":currencyProp}, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setAllCrypto(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.error('Error'));
  };

  const handleOpenOverlay = (imageURL, timeframe) => {
    setIsOverlayOpen(true);
    setSelectedTimeframe(timeframe);
    setHighResImage(imageURL);
  };

  if (isLoading) { return <LoadingPage1 height="h-[50px]" /> };

  return (
    <section className="text-gray-600 dark:text-white body-font">
      <div className="pt-2 pb-4 mx-auto flex justify-between flex-wrap flex-row">
        <div>
          <p className="py-2 text-xl font-semibold">{currencyProp}</p>
        </div>
        <div className="flex flex-wrap mb-20">
          <button onClick={() => { handleClick('4H'); setSelectedTimeframe('4H'); }}
            className={`sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none text-gray-500 cursor-pointer border-gray-500 tracking-wider rounded-t ${cryptoTime === '4H' ? 'bg-blue-200 border-darkblue-100 text-darkblue-100' : ''}`}>
            {fourH ? (
              <>
                <div className="flex flex-col items-center">
                  4 Hours
                  <span className="text-xs"><DateAgo dateProp={fourH.updated_at} /></span>
                </div>
                <CaseArrows caseProp={fourH.CryptoCase} classProp="w-6 h-auto ml-3" />
              </>
            ) : null}
          </button>
          <button onClick={() => { handleClick('D1'); setSelectedTimeframe('D1'); }}
            className={`sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none text-gray-500 cursor-pointer border-gray-500 tracking-wider rounded-t ${cryptoTime === 'D1' ? 'bg-blue-200 border-darkblue-100 text-darkblue-100' : ''}`}>
            {oneD ? (
              <>
                <div className="flex flex-col items-center">
                  Day 1
                  <span className="text-xs"><DateAgo dateProp={oneD.updated_at} /></span>
                </div>
                <CaseArrows caseProp={oneD.CryptoCase} classProp="w-6 h-auto ml-3" />
              </>
            ) : null}          </button>
          <button onClick={() => { handleClick('W'); setSelectedTimeframe('W'); }}
            className={`sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none text-gray-500 cursor-pointer border-gray-500 tracking-wider rounded-t ${cryptoTime === 'W' ? 'bg-blue-200 border-darkblue-100 text-darkblue-100' : ''}`}>
            {week ? (
              <>
                <div className="flex flex-col items-center">
                  Week
                  <span className="text-xs"><DateAgo dateProp={week.updated_at} /></span>
                </div>
                <CaseArrows caseProp={week.CryptoCase} classProp="w-6 h-auto ml-3" />
              </>
            ) : null}          </button>
          <button onClick={() => { handleClick('M'); setSelectedTimeframe('M'); }}
            className={`sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none text-gray-500 cursor-pointer border-gray-500 tracking-wider rounded-t ${cryptoTime === 'M' ? 'bg-blue-200 border-darkblue-100 text-darkblue-100' : ''}`}>
            {month ? (
              <>
                <div className="flex flex-col items-center">
                  Month
                  <span className="text-xs"><DateAgo dateProp={month.updated_at} /></span>
                </div>
                <CaseArrows caseProp={month.CryptoCase} classProp="w-6 h-auto ml-3" />
              </>
            ) : null}          </button>
        </div>

        <div>
  {['4H', 'D1', 'W', 'M'].map((divNumber, index) => (
    <div key={index} className={`${cryptoTime === divNumber ? 'block' : 'hidden'}`}>
      {allCrypto.CryptoImage && (
        <>
          <img 
            className="block lg:px-20 mx-auto mb-10 object-cover object-center rounded cursor-pointer" 
            src={`${process.env.REACT_APP_LOCALHOST_IMG}/${allCrypto.CryptoImage}`} 
            onClick={() => handleOpenOverlay(`${process.env.REACT_APP_LOCALHOST_IMG}/${allCrypto.CryptoImage}`, divNumber)} 
            onContextMenu={(e) => e.preventDefault()} />
          <div className="flex flex-col h-full">
            <p className="lg:w-3/4 mx-auto py-2 leading-relaxed dark:text-gray-300 text-gray-700 text-base">{allCrypto.CryptoText}</p>
          </div>
        </>
      )}
    </div>
  ))}
</div>

      </div>

      {isOverlayOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50"
        onContextMenu={(e) => e.preventDefault()}>
          <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center">
            <img className="max-w-full max-h-full" src={highResImage} alt="High resolution" />
            <button onClick={() => setIsOverlayOpen(false)} className="mt-4 bg-blue-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
