import UsersTable from "./UsersTable";
import AdmNavbar from "../AdmNavbar";
import Footer from "../../globalElements/Footer";
import DataCard from "./DataCard";
import { Toaster } from "react-hot-toast";

export default function Dashboard() {
  return (
    <section className="dark:bg-gray-900 dark:text-white">
      <AdmNavbar />
      <Toaster/>
      <div className="flex min-h-screen">
        <div className="w-full flex flex-col mx-10">
          <div className="transition-all duration-1000 ease-in-out">

              <div className="w-full rounded-md pt-5 pb-2 flex flex-col gap-5">
                <DataCard />
                <UsersTable />
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

