import { Routes, Route, useNavigate } from "react-router";
import HomePages from "./Home/HomePage";
import SignIn from "./authentication/SignIn";
import SignUp from "./authentication/SignUp";
import AdmHomeForex from "./Admin/AdmForex/AdmHomeForex";
import AdmHomeCrypto from "./Admin/AdmCrypto/AdmHomeCrypto";
import SubsHomeForex from "./Subscriber/SubsForex/SubsHomeForex";
import SubsHomeCrypto from "./Subscriber/SubsCrypto/SubsHomeCrypto";
import Pricing from "./Pricing/Pricing";
import Dashboard from "./Admin/Dashboard/Dashboard";
import AdmNotifs from "./Notifs/AdmNotifs/AdmNotifs";
import ElliotWaveSchool from "./ElliotWaveSchool/ElliotWaveSchool";
import { RequireAuth, SubscriberRoute } from "./globalElements/RequireAuth";

import Articles from "./Blog/Articles";
import BlogPage from "./Blog/BlogPages";
import NotFoundPage from "./globalElements/NotFoundPage";

function App() {

  return (
    <Routes>
      <Route path="/" element={<HomePages />} />
      <Route path="/subscriber/elliotwaveschool" element={<ElliotWaveSchool />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="/article/:id" element={<Articles />} />
      <Route path="/articles" element={<BlogPage />} />
      <Route path="*" element={<NotFoundPage />} />

      {/* Admin : */}
      <Route path="/admin/forex" element={
        <RequireAuth requiredRole="admin">
          <AdmHomeForex />
        </RequireAuth>
      } />

      <Route path="/admin/crypto" element={
        <RequireAuth requiredRole="admin">
          <AdmHomeCrypto />
        </RequireAuth>
      } />

      <Route path="/admin/dashboard" element={
        <RequireAuth requiredRole="admin">
          <Dashboard />
        </RequireAuth>
      } />

      <Route path="/admin/updates" element={
        <RequireAuth requiredRole="admin">
          <AdmNotifs />
        </RequireAuth>
      } />

      {/* Subscriber : */}
      <Route path="/subscriber/forex" element={
        <SubscriberRoute offerRequired="forex">
          <SubsHomeForex />
        </SubscriberRoute>
      } />

      <Route path="/subscriber/crypto" element={
        <SubscriberRoute offerRequired="crypto">
          <SubsHomeCrypto />
        </SubscriberRoute>
      } />

      {/* Unsubscriber : */}
      <Route path="/memberplans" element={
        <Pricing />
      } />
    </Routes>
  );
}

export default App;
