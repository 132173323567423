
export default function JoinNow() {
  return (
    <section id="JoinUs" className="relative z-10 overflow-hidden text-white bg-darkblue-100 py-20 lg:py-[115px]">
      <div className="container mx-auto">
        <div className="relative overflow-hidden">
          <div className="-mx-4 flex flex-wrap items-stretch">
            <div className="w-full px-4">
              <div className="mx-auto text-center max-w-5xl ">
                <h2 className="mb-5 text-3xl font-bold md:text-[38px] md:leading-[1.44]">
                  <span>Join our Elliott wave school Community <br /> Become a member, be an Elliott wave school Trader</span>
                </h2>
                <div className="w-full">
                  <a href="/memberplans" className="flex mb-3 w-44 justify-center m-auto rounded-md border border-transparent bg-white/[0.12] p-3 text-base font-medium text-white transition hover:text-gray-900 hover:bg-white">
                    Join Now
                  </a>
                </div>
                <p className="mx-auto mt-5 text-sm leading-[1.5]">
                  There is always one wave after another. Do not miss the next one.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <span className="absolute left-0 top-0">
          <svg width="495" height="470" viewBox="0 0 495 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="55" cy="442" r="138" stroke="white" strokeOpacity="0.04" strokeWidth="50" />
            <circle cx="446" r="39" stroke="white" strokeOpacity="0.04" strokeWidth="20" />
            <path d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z" stroke="white" strokeOpacity="0.08" strokeWidth="12" />
          </svg>
        </span>
        <span className="absolute bottom-0 right-0">
          <svg width="493" height="470" viewBox="0 0 493 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="462" cy="5" r="138" stroke="white" strokeOpacity="0.04" strokeWidth="50" />
            <circle cx="49" cy="470" r="39" stroke="white" strokeOpacity="0.04" strokeWidth="20" />
            <path d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z" stroke="white" strokeOpacity="0.06" strokeWidth="13" />
          </svg>
        </span>
      </div>;

    </section>
  )
}