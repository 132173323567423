import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Dropdown } from "flowbite-react";
import { Logout } from "./AllToasts";
import { Toaster } from "react-hot-toast";
import ModeSwitcher from "./ModeSwitcher";
import ExportEmails from "./ExportEmails";

export default function UserIcon() {
  const username = localStorage.getItem('name');
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const myoffer = localStorage.getItem('myoffer');

  return (
    <div className="flex items-center gap-2 pr-16 lg:pr-0">
      <Toaster />
      {token ? (
        <Dropdown label="Profile" dismissOnClick={false}
          renderTrigger={() =>
            <span className='cursor-pointer p-2 backdrop-blur-sm text-white bg-white/20 hover:bg-white/30 rounded-lg'>
              <UserCircleIcon className='h-6 w-auto text-white' />
            </span>}>
          <Dropdown.Item className="capitalize">{username}</Dropdown.Item>
          {role === "admin" ? (
            <>
              <Dropdown.Item href="/admin/updates">Intraday updates</Dropdown.Item>
              <Dropdown.Item href="/admin/dashboard">Dashboard</Dropdown.Item>
              <Dropdown.Item href="/admin/forex">Forex for admin</Dropdown.Item>
              <Dropdown.Item href="/admin/crypto">Crypto for admin</Dropdown.Item>
              <Dropdown.Item href="/subscriber/forex">Forex for subscriber</Dropdown.Item>
              <Dropdown.Item href="/subscriber/crypto">Crypto for subscriber</Dropdown.Item>
              <Dropdown.Item><ExportEmails/> </Dropdown.Item>
            </>
          ) : role === "subscriber" && myoffer === "forex" ? (
            <Dropdown.Item href="/subscriber/forex">Forex</Dropdown.Item>
          ) : role === "subscriber" && myoffer === "crypto" ? (
            <Dropdown.Item href="/subscriber/crypto">Crypto</Dropdown.Item>
          ) : role === "subscriber" && myoffer === "both" ? (
            <>
            <Dropdown.Item href="/subscriber/forex">Forex</Dropdown.Item>
            <Dropdown.Item href="/subscriber/crypto">Crypto</Dropdown.Item>
            </>
          ) : ("")
          }
          <Dropdown.Item ><Logout /></Dropdown.Item>
        </Dropdown>
      ) : (
        <a href='/signin' className='cursor-pointer p-2 backdrop-blur-sm text-white bg-white/20 hover:bg-white/30 rounded-lg'>
          <UserCircleIcon className='h-6 w-auto text-white' />
        </a>
      )}
      <ModeSwitcher />
    </div>
  )
}