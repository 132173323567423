import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function Part1() {
  return (
    <section className="relative z-10 overflow-hidden bg-white dark:bg-gray-900 py-16">
      <div className="container mx-auto">
        <div className="relative overflow-hidden">
          <div className="-mx-4 flex flex-wrap items-stretch">
            <div className="w-full px-4">
              <div className="mx-auto text-center max-w-5xl ">
                <h2 className="mb-2.5 text-3xl font-bold text-darkblue-100 dark:text-white md:text-[38px] md:leading-[1.44]">
                  <span>Are you interested in Elliott wave school Analysis?</span>
                </h2>
                <p className="mx-auto mb-4 text-base leading-[1.5] text-gray-900 dark:text-white">
                You are in the right place , with our services we are helping traders and investors to spot the next direction of the market.
With the Elliott wave school Analysis, which describes, “how the people and crowd behaves” through the market cycles.
What is the best about the Elliott wave school Analysis is that market mood and sentiment can be recognized and defined through different patterns that will repeat through time on different periods, and that even can learn how to recognize them.
                </p>
                <p className="mb-2.5 text-2xl font-bold text-darkblue-100 dark:text-white md:text-[22px] md:leading-[1.44]">
                We track the Elliott wave school patterns in real time on cryptos, currencies Forex, Crude Oil, Gold, Silver since 8 years
                </p>
                <p className="mx-auto mb-4 text-base leading-[1.5] text-gray-900 dark:text-white">
                  Join us now and start tracking the markets with Elliott wave school Analysis Techniques. Become an Elliott wave school Trader.
                </p>
                <div className="w-full">
                  <a href="/memberplans" className="flex w-44 justify-center m-auto rounded-md border border-transparent bg-darkblue-100 p-3 text-base font-medium text-white transition hover:bg-blue-800">
                    APPLY HERE
                    <ChevronDownIcon className="h-6 ml-1 w-auto text-white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}