import { CheckCircleIcon } from "@heroicons/react/24/solid";
import SubsNavbar from "../Subscriber/SubsNavbar";
import Footer from "../globalElements/Footer";

export default function ElliotWaveSchool() {
  return (
    <section className="w-full bg-white dark:bg-gray-900">
      <SubsNavbar />
      <div className="container mx-auto flex items-center justify-center px-6 py-16">
        <div className="max-w-3xl">
          <p className="text-2xl font-semibold text-darkblue-200 md:text-3xl dark:text-white">Elliott Wave School</p>
          <p className="mt-4 text-gray-600 dark:text-gray-300">
            Explore the World of Forex and Crypto Through Elliott Wave Analysis. <br />
            Join our Elliott Wave School where we delve into the intricate world of Elliott Wave theory applied to the dynamic forex and cryptocurrency markets. <br />
            Equip yourself with the knowledge to forecast market trends and enhance your trading strategies.
          </p>

          <h2 className="mb-2 mt-4 text-lg font-semibold text-gray-900 dark:text-white">What We Offer :</h2>
          <ul className="list-inside space-y-1 text-gray-600 dark:text-gray-300">
            <li className="mb-3 flex items-center">
              <CheckCircleIcon className="me-2 h-4 w-4 flex-shrink-0 text-darkblue-200 dark:text-darkblue-100" />
              Courses : Comprehensive modules covering everything from basic concepts to advanced techniques in Elliott Wave theory.
            </li>
            <li className="mb-3 flex items-center">
              <CheckCircleIcon className="me-2 h-4 w-4 flex-shrink-0 text-darkblue-200 dark:text-darkblue-100" />
              Exercises : Practical assignments to help you apply your knowledge and refine your analytical skills.
            </li>
            <li className="mb-3 flex items-center">
              <CheckCircleIcon className="me-2 h-4 w-4 flex-shrink-0 text-darkblue-200 dark:text-darkblue-100" />
              Live Sessions : Regular live webinars with experts where you can learn in real time and get answers to your questions.
            </li>
          </ul>

          <div className="mt-6 flex items-center gap-x-3">
            <a href="https://discord.gg/z8eHARr38Q" className="mb-2 me-2 inline-flex items-center rounded-lg bg-[#5865F2] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#5865F2]/90 focus:outline-none focus:ring-4 focus:ring-[#5865F2]/50 dark:focus:ring-[#5865F2]/55">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-discord me-3 h-5 w-auto" viewBox="0 0 16 16">
                <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
              </svg>
              Join us on Discord
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}
