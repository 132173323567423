import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { clearExpiredSubscriptions } from "../globalElements/clearExpiredSubscriptions";

export default function Payments() {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');

  useEffect(() => {
    clearExpiredSubscriptions();
    axios.get(`${localhost}/user`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.error('Error'));
  }, [])

  const handleClick = () => {
    if (token) {
      const telegramUrl = "https://t.me/elliotelprofessor";
      window.location.href = telegramUrl;
    } else {
      navigate("/signin");
    }
  }

  const methods = [
    { curr: "USDT (TRC20)", res: "TQ4HY7pAi7bFjrXETPphasxBEJvrgMxxNh" },
    { curr: "USDT (ERC20)", res: "0xf2c678f85670b517d09bab505300b0c95f4d8990" },
    { curr: "Bitcoin", res: "1JL6thmbwBDFvYCUGHsww9tZN81SytJhhd" }
  ];

  return (
    <section id="Payments" className="bg-white dark:bg-gray-900 text-gray-800 dark:text-white pt-12">
      <div className="container mx-auto flex flex-col space-y-6 px-6 py-10 lg:h-[32rem] lg:flex-row lg:items-center lg:py-16">
        <div className="w-full lg:w-1/2">
          <div className="lg:max-w-lg">
            <h1 className="text-3xl font-bold tracking-wide text-gray-800 lg:text-5xl dark:text-white">We Accept Crypto Payments :</h1>

            <div className="mt-8 space-y-5">
              <p className="text-gray-700 dark:text-gray-200">Use the following info to complete your order :</p>

              {methods.map((item, index) => (
                <div key={index} className="-mx-2 flex items-center text-gray-700 dark:text-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" className="mx-2 h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <div className="ml-2">
                    <p>{item.curr}</p>
                    <p>{item.res}</p>
                  </div>
                </div>
              ))
              }
            </div>
          </div>
          <p className="pt-4">
            Once the operation is complete you can contact our support team on Telegram, <br />
            make sure to send us a screenshot showing the transaction with plan chosen... <br /> Our team will check it for you and add you to the EWM PRO.
          </p>
          <button type="button" onClick={handleClick}
          className="m-1 mt-8 h-10 transform rounded-md bg-blue-500 px-4 py-2 text-white transition-colors duration-200 hover:bg-blue-400 focus:bg-blue-400 focus:outline-none">Chat with us</button>

        </div>

        <div className="flex h-96 w-full items-center justify-center lg:w-1/2">
          <img className="mx-auto h-full w-full rounded-md object-cover lg:max-w-2xl"
            src={`${process.env.PUBLIC_URL}/items/images/hero/pricing-cover.png`} alt="Hero" />
        </div>
      </div>
    </section>
  )
}