import axios from "axios";
import { useEffect, useState } from "react";
import { LoadingPage2 } from "../../globalElements/LoadingPage";
import { clearExpiredSubscriptions } from "../../globalElements/clearExpiredSubscriptions";

export default function DataCard(){
  const [allData, setAllData]= useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');
  
  useEffect(()=>{
    clearExpiredSubscriptions();
    axios.get(`${process.env.REACT_APP_LOCALHOST}/datacard`, { headers: { 'Authorization': `Bearer ${token}` } })
    .then((res)=>{
      setAllData(res.data);
      setIsLoading(false);
  })
    .catch((err)=>console.error('Error'));
  },[]);

  const cardinfo = [
    {
      title: "Total Users",
      number: allData.allUsers,
    },
    {
      title: "Total Subscribers",
      number: allData.allSubs,
    },
    {
      title: "Total Unsubscribers",
      number: allData.allNotSubs,
    },
  ];

  if(isLoading){return <LoadingPage2 height="h-32"/>};
  return(
    <div className="flex flex-col gap-7 mb-6 ">
    <div className="flex flex-col lg:flex-row w-full gap-5 justify-between">
      {cardinfo.map((data, index) => {
        return (
          <div key={index} className="cursor-pointer rounded-lg w-full dark:bg-gray-900 border-[1px] shadow-md border-gray-100 dark:border-gray-700">
            <div className="p-5 w-full flex-col flex gap-4">
              <div className="text-[#637381] dark:text-gray-400 text-sm font-semibold">
                {data.title}
              </div>
              <div className="flex justify-between">
                <div className="text-black dark:text-white text-2xl font-extrabold">
                  {data.number}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
  )
}