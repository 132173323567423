import AdmNavbar from "../AdmNavbar";
import AdmArticleCrypto from "./AdmArticleCrypto";

export default function AdmHomeCrypto() {
  return (
    <section className="min-h-screen bg-white text-gray-800 dark:bg-gray-900 dark:text-white">
      <AdmNavbar />
      <div className="mx-4 mt-2 max-w-screen-xl sm:mx-8 xl:mx-auto">
        <div className="grid grid-cols-8 pt-5 pb-10 sm:grid-cols-10">

          <div className="sm:col-start-2 sm:col-end-10 sm:col-auto col-span-8 rounded-xl bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-white px-4 sm:px-8 sm:shadow">
            <div className="pt-4">
              <h1 className="py-2 text-2xl font-semibold">Crypto</h1>
            </div>
            <hr className="mt-4 mb-8" />
            <AdmArticleCrypto />
          </div>

        </div>
      </div>
    </section>
  )
}