import { useEffect, useState } from "react";
import SubsNavbar from "../SubsNavbar";
import SubsArticleForex from "./SubsArticleForex";
import { LoadingPage1 } from "../../globalElements/LoadingPage";
import axios from "axios";
import { clearExpiredSubscriptions } from "../../globalElements/clearExpiredSubscriptions";

export default function SubsHomeForex() {
  const [isLoading, setIsLoading] = useState(true);
  const [currency, setCurrency] = useState("ALL");
  const [forexNames, setForexNames] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    clearExpiredSubscriptions();
    setIsLoading(false);
    axios.get(`${process.env.REACT_APP_LOCALHOST}/getforexnames`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => setForexNames(res.data))
      .catch((err) => console.error('Error'));
  }, [trigger])

  const handleCurrency = (curr) => {
    setIsLoading(true);
    setCurrency(curr);
    setTrigger(t => !t);
  }

  return (
    <section className="min-h-screen bg-white text-gray-800 dark:bg-gray-900 dark:text-white">
      <SubsNavbar />
      <div className="mx-4 mt-2 max-w-screen-xl sm:mx-8 xl:mx-auto">
        <div className="grid grid-cols-8 pt-3 pb-10 sm:grid-cols-10">
          <div className="relative my-4 w-56 sm:hidden">
            <input className="peer hidden" type="checkbox" name="select-1" id="select-1" />
            <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-darkblue-100 peer-checked:ring">Accounts </label>
            <svg xmlns="http://www.w3.org/2000/svg" className="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
            <ul className="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("ALL")} >ALL</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("EUR/USD")} >EUR/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("GBP/USD")} >GBP/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("NZD/USD")} >NZD/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("USD/CAD")} >USD/CAD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("USD/JPY")} >USD/JPY</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("INDEX/USD")} >INDEX/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("OIL/USD")} >OIL/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("XAU/USD")} >XAU/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("XAG/USD")} >XAG/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("NQ/USD")} >NQ/USD</li>
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-darkblue-100 hover:text-white"
                onClick={() => handleCurrency("US30/USD")} >US30/USD</li>
            </ul>
          </div>

          <div className="col-span-2 hidden sm:block">
            <div className="pr-4">
              <p className="text-darkblue-100 py-2 text-xl font-semibold border-b-2 border-b-darkblue-100">Forex</p>
            </div>

            <ul>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'ALL' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("ALL")} >ALL</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'BTCUSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("EUR/USD")} >EUR/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'EGYUSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("GBP/USD")} >GBP/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'EURMSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("NZD/USD")} >NZD/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'EURUSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("USD/CAD")} >USD/CAD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'GBPUSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("USD/JPY")} >USD/JPY</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'MADMSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("INDEX/USD")} >INDEX/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'MADUSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("OIL/USD")} >OIL/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'USDCHF' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("XAU/USD")} >XAU/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'MADMSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("XAG/USD")} >XAG/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'MADUSD' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("NQ/USD")} >NQ/USD</li>
              <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-darkblue-100 hover:text-darkblue-100 ${currency === 'USDCHF' ? ' text-darkblue-100 border-l-darkblue-100' : ''}`}
                onClick={() => handleCurrency("US30/USD")} >US30/USD</li>
            </ul>
          </div>

          <div className="col-span-8 pb-5 rounded-xl bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-white px-4 sm:px-8 sm:shadow">
            <div className="pt-4">
              <h1 className="py-2 text-2xl font-semibold">Forex</h1>
            </div>
            <hr className="mt-3 mb-6" />
            {
              isLoading ? (<LoadingPage1 height="h-[50rem]" />)
                : (currency === "ALL" ?
                  (<div>
                    {forexNames.map((item, index) => (
                      <div key={index} className="py-2">
                        <SubsArticleForex currencyProp={item} trigger={trigger} />
                      </div>
                    ))}
                  </div>
                  ) : (<SubsArticleForex currencyProp={currency} trigger={trigger} />)
                )
            }
          </div>
        </div>
      </div>
    </section>
  )
}