import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <section className="bg-white dark:bg-gray-900 w-full">
      <Navbar />
      <div className="container flex items-center justify-center min-h-screen px-6 py-16 mx-auto">
        <div>
          <p className="text-sm font-medium text-darkblue-200 dark:text-darkblue-100">404 error</p>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">We can’t find that page</h1>
          <p className="mt-4 text-gray-500 dark:text-gray-400">Sorry, the page you are looking for doesn't exist or has been moved.</p>

          <div className="flex items-center mt-6 gap-x-3">
            <button onClick={() => navigate(-1)} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>

              <span>Go back</span>
            </button>

            <a href="/" className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-darkblue-200 rounded-lg shrink-0 sm:w-auto hover:bg-darkblue-200 dark:hover:bg-darkblue-100 dark:bg-darkblue-200">
              Take me home
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}