import { useState } from "react"
import { Upload } from "react-bootstrap-icons";
import { useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-hot-toast";
import { DoneToast, ErrToast } from "../../globalElements/AllToasts";
import { CaseArrows } from "../../globalElements/CaseArrows";
import Select from 'react-select';

export default function AdmArticleForex() {
  const navigate = useNavigate();
  const localhost = process.env.REACT_APP_LOCALHOST;
  const [forexName, setForexName] = useState("");
  const [forexTime, setForexTime] = useState("");
  const [forexImage, setForexImage] = useState(null);
  const [forexTxt, setForexTxt] = useState("");
  const [forexCase, setForexCase] = useState("");

  const token = localStorage.getItem('token');

  const CreateForex = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("ForexName", forexName);
    formData.append("ForexTime", forexTime);
    formData.append("ForexImage", forexImage);
    formData.append("ForexText", forexTxt);
    formData.append("ForexCase", forexCase);

    axios.post(`${localhost}/forex`, formData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        toast(<DoneToast text="The Data added successfully" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        setTimeout(() => { navigate("/admin/forex") }, 2000);
      })
      .catch((err) => {
        toast(<ErrToast text="There is a problem!" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        console.error('Error');
      });
  }

  const namesOptions = [
    {value:"EUR/USD", label: "EUR/USD"},
    {value:"GBP/USD", label: "GBP/USD"},
    {value:"NZD/USD", label: "NZD/USD"},
    {value:"USD/CAD", label: "USD/CAD"},
    {value:"USD/JPY", label: "USD/JPY"},
    {value:"INDEX/USD", label: "INDEX/USD"},
    {value:"OIL/USD", label: "OIL/USD"},
    {value:"XAU/USD", label: "XAU/USD"},
    {value:"XAG/USD", label: "XAG/USD"},
    {value:"NQ/USD", label: "NQ/USD"},
    {value:"US30/USD", label: "US30/USD"},
  ];

  const timeOptions = [
    {value: "4H", label: "4 Hours"},
    {value: "D1", label: "Day 1"},
    {value: "W", label: "Week"},
    {value: "M", label: "Month"},
  ];

  const casesOptions = [
    { value: "down", label: (<div className="flex">Down <CaseArrows caseProp="down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "down_up", label: (<div className="flex">Down up <CaseArrows caseProp="down_up" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "down_up_down", label: (<div className="flex">Down up down <CaseArrows caseProp="down_up_down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "horizontal", label: (<div className="flex">Horizontal <CaseArrows caseProp="horizontal" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "horizontal_down", label: (<div className="flex">Horizontal down <CaseArrows caseProp="horizontal_down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "horizontal_up", label: (<div className="flex">Horizontal up <CaseArrows caseProp="horizontal_up" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "up", label: (<div className="flex">Up <CaseArrows caseProp="up" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "up_down", label: (<div className="flex">Up down <CaseArrows caseProp="up_down" classProp="ml-2 h-auto w-5" /></div>) },
    { value: "up_down_up", label: (<div className="flex">Up down up <CaseArrows caseProp="up_down_up" classProp="ml-2 h-auto w-5" /></div>) },
  ];

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 pt-2 pb-4 mx-auto flex flex-wrap flex-col">

        <div className="">
          <form onSubmit={CreateForex}>
            <div className="xl:w-3/4 lg:w-2/3 md:w-3/4 w-3/4 block mx-auto mb-5 object-cover bg-blue-600 lg:h-[34rem] h-[20rem] object-center rounded" alt="hero">
              <label htmlFor="chartImage" className="flex justify-center items-center cursor-pointer w-full h-full">
                <div className="flex flex-col justify-center">
                  <Upload className="text-white h-10 lg:h-16 sm:h-12 w-auto" />
                  <p className="mt-3 text-white text-center text-xs sm:text-sm"><span className="font-semibold">Click to upload</span> the chart image</p>
                </div>
                <input id="chartImage" type="file" onChange={(e) => setForexImage(e.target.files[0])} className="hidden" accept="image/jpeg, image/jpg, image/png" required />
              </label>
            </div>

            <div className="mx-auto xl:w-3/4 lg:w-2/3 md:w-3/4 w-3/4">
              {/* Select the forex : */}
              <div className="mb-5">
                <div className="max-w-sm mb-3">
                  <label htmlFor="forexs" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Currency</label>
                  <Select id="forexs" value={namesOptions.find(option => option.value === forexName)} className="rounded-lg text-gray-800 bg-white"
                    onChange={(option) => setForexName(option.value)} options={namesOptions} isSearchable={false} />
                </div>
                <div className="max-w-sm mb-3">
                  <label htmlFor="times" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Time</label>
                  <Select id="times" value={timeOptions.find(option => option.value === forexTime)} className="rounded-lg text-gray-800 bg-white"
                    onChange={(option) => setForexTime(option.value)} options={timeOptions} isSearchable={false} />
                </div>
                <div className="max-w-sm mb-3">
                  <label htmlFor="arrows" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Charts</label>
                  <Select id="arrows" value={casesOptions.find(option => option.value === forexCase)} className="rounded-lg text-gray-800 bg-white"
                    onChange={(option) => setForexCase(option.value)} options={casesOptions} isSearchable={false} />
                </div>
              </div>

              <label htmlFor="times" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
              <textarea rows={5} type="text" onChange={(e) => setForexTxt(e.target.value)} placeholder="type here..." className="w-full leading-relaxed rounded-lg dark:text-gray-300 text-gray-700 text-base dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-600 dark:focus:border-blue-600" />
              <div className="flex justify-end">
                <input type="submit" value="Submit" className="cursor-pointer mt-2 py-2 px-3 w-28 bg-blue-600 text-white rounded-lg" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}