import axios from "axios";
import toast from "react-hot-toast";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { DoneToast, ErrToast } from "../../globalElements/AllToasts";

export default function DeleteUser({ user_id }) {
  const token = localStorage.getItem('token');

  const deleteUser = () => {
    axios.delete(`${process.env.REACT_APP_LOCALHOST}/users/${user_id}`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(() => {
        toast(<DoneToast text="The user deleted successfully" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        setTimeout(() => { window.location.reload() }, 2000);
      })
      .catch((err) => {
        toast(<ErrToast text="There is problem!" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        console.error('Error');
      });
  }

  const confirmDelete = () => {
    toast((t) => (
      <div className="relative p-4 text-center bg-white dark:bg-gray-900 text-gray-800 dark:text-white rounded-lg shadow sm:p-5">
        <button onClick={() => toast.dismiss(t.id)} type="button" className="text-gray-900 dark:text-white absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-300 dark:hover:bg-gray-700 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center">
          <XMarkIcon className="w-6 h-6" />
        </button>
        <TrashIcon className="w-10 h-10 mb-1 mx-auto" />
        <p className="mb-3 text-xs dark:text-white text-gray-900">Are you sure?</p>
        <div className="flex justify-center items-center space-x-4">
          <button onClick={() => {
            deleteUser();
            toast.dismiss(t.id);
          }} type="submit" className="py-2 px-3 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700">
            Yes, I'm sure
          </button>
          <button onClick={() => toast.dismiss(t.id)} type="button" className="py-2 px-3 text-xs font-medium border border-blue-600 bg-transparent text-blue-600 rounded-lg focus:z-10">
            No, cancel
          </button>
        </div>
      </div>),
      {
        style: { background: 'none', boxShadow: 'none' },
        duration: 5000,
        position: 'top-center',
      });
  }

  return (
    <button onClick={confirmDelete} className="flex ml-auto items-center bg-red-600 text-white hover:bg-red-700 rounded-md px-3 py-2 text-center text-sm font-medium outline-none">
      Delete
    </button>
  )
}