import { useState } from "react";
import axios from "axios";
import Select from 'react-select';
import toast from "react-hot-toast";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { DoneToast, ErrToast } from "../../globalElements/AllToasts";

export default function PlusBtn() {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("");
  const [notifsName, setNotifsName] = useState("");
  const [notifsImage, setNotifsImage] = useState(null);
  const [notifsText, setNotifsText] = useState("");

  const addNotif = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("type", type);
    formData.append("NotifsName", notifsName);
    formData.append("NotifsImage", notifsImage);
    formData.append("NotifsText", notifsText);

    axios.post(`${localhost}/notifs`, formData, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        toast(<DoneToast text="The notification added successfully" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        setTimeout(() => { window.location.reload() }, 2000);
      })
      .catch((err) => {
        toast(<ErrToast text="There is problem!" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        console.error('Error');
      });
  };

  const typesOptions = [
    { value: "crypto", label: "Crypto" },
    { value: "forex", label: "Forex" }
  ];

  const forexOptions = [
    { value: "EUR/USD", label: "EUR/USD" },
    { value: "GBP/USD", label: "GBP/USD" },
    { value: "NZD/USD", label: "NZD/USD" },
    { value: "USD/CAD", label: "USD/CAD" },
    { value: "USD/JPY", label: "USD/JPY" },
    { value: "INDEX/USD", label: "INDEX/USD" },
    { value: "OIL/USD", label: "OIL/USD" },
    { value: "XAU/USD", label: "XAU/USD" },
    { value: "XAG/USD", label: "XAG/USD" },
    { value: "NQ/USD", label: "NQ/USD" },
    { value: "US30/USD", label: "US30/USD" }
  ];

  const cryptoOptions = [
    { value: "BTC/USDT", label: "BTC/USDT" },
    { value: "ETH/USDT", label: "ETH/USDT" },
    { value: "BNB/USDT", label: "BNB/USDT" },
    { value: "LTC/USDT", label: "LTC/USDT" },
    { value: "DASH/USDT", label: "DASH/USDT" },
    { value: "XRP/USDT", label: "XRP/USDT" },
    { value: "BCH/USDT", label: "BCH/USDT" }
  ];

  return (
    <>
      <button onClick={() => setShowModal(true)} className="rounded-full bg-blue-600 p-3">
        <PlusIcon className="text-white h-5 w-auto" />
      </button>
      {showModal ? (
        <div className="bg-blue-600/30 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative animate-fade-up animate-duration-[1500ms] animate-ease-out w-auto my-6 mx-auto max-w-3xl">
            <div className="mx-auto max-w-md pt-20">
              <div className="rounded-xl bg-white text-gray-800 dark:text-white dark:bg-gray-900 shadow-lg">
                <div className="container p-3 mx-auto flex flex-wrap flex-col">

                  <form onSubmit={addNotif}>
                    <div className="mx-auto p-5">
                      <button className="float-end text-gray-900 dark:text-white bg-transparent hover:bg-gray-300 dark:hover:bg-gray-700 hover:text-gray-900 rounded-lg p-1 inline-flex items-center" onClick={() => setShowModal(false)}>
                        <XMarkIcon className="h-5 w-5 text-gray-800 dark:text-white" />
                      </button>
                      <p className="block mb-4 text-lg font-medium text-gray-900 dark:text-white">Add intraday update</p>
                      <div className="max-w-sm mb-3">
                        <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type (optional)</label>
                        <Select id="type" value={typesOptions.find(option => option.value === type)} className="rounded-lg text-gray-800 bg-white"
                          onChange={(option) => setType(option.value)} options={typesOptions} isSearchable={false} />
                      </div>
                      <div className="max-w-sm mb-3">
                        <label htmlFor="NotifsName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Currency (optional)</label>
                        <Select id="NotifsName" value={
                          type === "forex" ? forexOptions.find(option => option.value === notifsName)
                            : cryptoOptions.find(option => option.value === notifsName)}
                          className="rounded-lg text-gray-800 bg-white"
                          onChange={(option) => setNotifsName(option.value)} options={type === "forex" ? forexOptions : cryptoOptions}
                          isSearchable={false} isDisabled={type === "" ? true : false} />
                      </div>
                      <div className="max-w-sm mb-3">
                        <label htmlFor="NotifsImage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image (optional)</label>
                        <input id="NotifsImage" type="file" className="rounded-md border border-gray-400 text-gray-800 bg-white w-full" accept="image/jpeg, image/jpg, image/png"
                          onChange={(e) => setNotifsImage(e.target.files[0])}/>
                      </div>
                      <div className="max-w-sm mb-3">
                        <label htmlFor="NotifsText" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Text<span className="text-red-600"> *</span></label>
                        <textarea id="NotifsText" value={notifsText} className="rounded-md text-gray-800 bg-white w-full"
                          onChange={(e) => setNotifsText(e.target.value)} required>
                        </textarea>
                      </div>
                      <input type="submit" value="Submit" className="block cursor-pointer mt-2 py-2 px-3 mb-3 w-28 bg-blue-600 text-white float-end rounded-lg" />
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ""}
    </>
  )
}