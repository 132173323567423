import articles from "./articles.json";

export default function BottomButtons({ currentID }) {
  const lastElement = articles.slice(-1)[0];
  
  return (
    <div className="py-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between gap-2">
          {currentID <= 1 ? (
            <></>
          ) : (
            <a href={`/article/${currentID - 1}`} className="inline-flex items-center px-3 py-1.5 rounded-md text-darkblue-100 hover:bg-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
              <span className="ml-2 font-bold text-lg">Back</span>
            </a>
          )}

          {currentID >= lastElement.id ? (
            <></>
          ) : (
            <a href={`/article/${currentID + 1}`} className="inline-flex items-center px-3 py-1.5 rounded-md text-darkblue-100 hover:bg-indigo-50">
              <span className="mr-2 font-bold text-lg">Next</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}