import { useState } from "react";
import Navbar from "../globalElements/Navbar";
import { PricingCard, List } from "./PricingCard";
import Payments from "./Payments";
import Disclaimers from "./Disclaimers";

const Pricing = () => {
  const [offer, setOffer] = useState("threeMonths");

  return (
    <section>
      <Navbar />

      <div className="relative z-10 overflow-hidden bg-white pt-32 dark:bg-gray-900">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[510px] text-center">
                <span className="mb-2 block text-lg font-semibold text-darkblue-100">Member Plans</span>
                <h2 className="mb-3 text-3xl font-bold leading-[1.208] text-gray-800 dark:text-white sm:text-4xl md:text-[40px]">Our Pricing Plan</h2>
                <p className="text-base dark:text-gray-300 text-gray-800">Dive into Forex and Metals, Crypto investments, and expert-led education at Elliot Wave School</p>
              </div>
              <div className="flex flex-col text-center w-full mb-20">
                <div className="flex mx-auto border-2 border-darkblue-100 rounded overflow-hidden mt-6">
                  <button className={`py-1 px-4 focus:outline-none ${offer === "threeMonths" ? "bg-darkblue-100 text-white" : "text-darkblue-100"}`}
                    onClick={() => setOffer("threeMonths")} >3 Months</button>
                  <button className={`py-1 px-4 border-l-2 border-r-2 border-darkblue-100 focus:outline-none ${offer === "sixMonths" ? "bg-darkblue-100 text-white" : "text-darkblue-100"}`}
                    onClick={() => setOffer("sixMonths")} >6 Months</button>
                  <button className={`py-1 px-4 focus:outline-none ${offer === "oneYear" ? "bg-darkblue-100 text-white" : "text-darkblue-100"}`}
                    onClick={() => setOffer("oneYear")} >1 Year</button>
                </div>
              </div>
            </div>
          </div>

          {
            offer === "threeMonths" ?
              (
                <div className="mx-4 flex flex-wrap justify-center">
                  <div className="-mx-4 flex flex-wrap">
                    <PricingCard type="FX + METALS PORTAL" price="$170" subscription="month"
                      description="Elliott wave analysis help providing an overview of market direction for forex, metals, and indices." buttonText="Choose Forex" active>
                    </PricingCard>
                    <PricingCard type="CRYPTO PORTAL" price="$170" subscription="month"
                      description="Elliott wave analysis offer insights into direction within the crypto market." buttonText="Choose Crypto" active>
                    </PricingCard>
                    <PricingCard type="Elliott Wave School" price="" subscription="month"
                      description="Unlock the secrets of Elliott Wave theory with comprehensive courses and personalized mentorship. Master market analysis and forecast price movements
Elevate your trading skills to new heights. Join our community today and transform your trading journey." buttonText="Choose Elliot Wave School">
                    </PricingCard>
                  </div>
                </div>
              )
              : offer === "sixMonths" ?(
                <div className="mx-4 flex flex-wrap justify-center">
                  <div className="-mx-4 flex flex-wrap">
                    <PricingCard type="FX + METALS PORTAL" price="$300" subscription="6 months"
                      description="Elliott wave analysis help providing an overview of market direction for forex, metals, and indices." buttonText="Choose Forex" active>
                    </PricingCard>
                    <PricingCard type="CRYPTO PORTAL" price="$300" subscription="6 months"
                      description="Elliott wave analysis offer insights into direction within the crypto market." buttonText="Choose Crypto" active>
                    </PricingCard>
                    <PricingCard type="Elliott Wave School" price="" subscription="6 months"
                      description="Unlock the secrets of Elliott Wave theory with comprehensive courses and personalized mentorship. Master market analysis and forecast price movements
Elevate your trading skills to new heights. Join our community today and transform your trading journey." buttonText="Choose Elliot Wave School">
                    </PricingCard>
                  </div>
                </div>
              ) : (
                <div className="mx-4 flex flex-wrap justify-center">
                <div className="-mx-4 flex flex-wrap">
                  <PricingCard type="FX + METALS PORTAL" price="$470" subscription="year"
                    description="Elliott wave analysis help providing an overview of market direction for forex, metals, and indices." buttonText="Choose Forex" active>
                  </PricingCard>
                  <PricingCard type="CRYPTO PORTAL" price="$470" subscription="year"
                    description="Elliott wave analysis offer insights into direction within the crypto market." buttonText="Choose Crypto" active>
                  </PricingCard>
                  <PricingCard type="Elliott Wave School" price="" subscription="year"
                    description="Unlock the secrets of Elliott Wave theory with comprehensive courses and personalized mentorship. Master market analysis and forecast price movements
Elevate your trading skills to new heights. Join our community today and transform your trading journey." buttonText="Choose Elliot Wave School">
                  </PricingCard>
                </div>
              </div>
              )
          }
        </div>
      </div>

      <Payments/>
      <Disclaimers/>
    </section>
  );
};

export default Pricing;


