import { useParams } from "react-router";
import articles from "./articles.json";
import { useEffect, useState } from "react";
import NotFoundPage from "../globalElements/NotFoundPage";
import Navbar from "../globalElements/Navbar";
import Footer from "../globalElements/Footer";
import BottomButtons from "./BottomButtons";

// Modal component
function Modal({ isOpen, onClose, imageUrl }) {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center justify-center">
        <img src={imageUrl} alt="Clicked Image" className="max-w-full max-h-full" />
        <button onClick={onClose} className="mt-4 bg-blue-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Close
            </button>
      </div>
    </div>
  );
}

export default function Articles() {
  const { id } = useParams();
  const articleId = parseInt(id, 10);
  const [isLoading, setIsLoading] = useState(true);
  const [myData, setMyData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const foundArticle = articles.find(article => article.id === articleId);
    if (foundArticle) {
      setMyData(foundArticle);
      setIsLoading(false);
    } else {
      console.error('Article not found');
    }
  }, [articleId]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  if (isLoading) { return <NotFoundPage/> }

  return (
    <section className="font-inter bg-white text-gray-800 dark:text-white dark:bg-gray-900">
      <Navbar />
      <div className="relative bg-darkblue-100 pb-24 pt-[12rem]">
        <div className="mx-auto w-full max-w-lg px-5 max-md:px-4 md:max-w-2xl lg:max-w-4xl lg:px-11">
          <h1 className="font-manrope mb-8 text-4xl font-semibold leading-tight text-white min-[500px]:text-5xl">{myData.title}</h1>
        </div>
      </div>

      <div className="relative py-10 lg:py-16">
        <div className="mx-auto w-full max-w-lg px-5 max-md:px-3 md:max-w-2xl lg:max-w-4xl lg:px-11">
          <p className="font-manrope mb-12 text-2xl font-semibold leading-9 dark:text-white text-gray-900">
            {myData.title}
          </p>

          {
            Array.isArray(myData.text) ?
              myData.text.map((item, index) => (
                <p key={index} className="mb-3 text-lg font-normal leading-8 text-gray-700 dark:text-gray-300">
                  {item}
                </p>
              ))
              : <p className="mb-3 text-lg font-normal leading-8 text-gray-700 dark:text-gray-300">{myData.text}</p>
          }

          <div className="img mt-8 mb-14 w-full">
            <h2 className="font-manrope mb-8 text-center text-xl font-bold dark:text-white text-gray-900">Pictures</h2>
            {
              myData.images ?
                myData.images.map((item, index) => (
                  <div key={index} className="mb-3">
                    <img 
                      src={`${process.env.PUBLIC_URL}/items/blog/${item.imgFile}`} 
                      className="border-2 p-2 border-darkblue-100 rounded-lg cursor-pointer" 
                      alt={`Blog page ${index + 1}`} 
                      onClick={() => handleImageClick(item.imgFile)} 
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <p className="text-lg mt-3 font-medium dark:text-white text-gray-900">{item.imgDate}</p>
                  </div>
                ))
                : null
            }
          </div>
        </div>
      </div>

      <BottomButtons currentID={articleId} />
      <Footer />

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} imageUrl={`${process.env.PUBLIC_URL}/items/blog/${selectedImage}`} />
    </section>
  );
}
