import axios from "axios";
import { useEffect, useState } from "react";
import OneNotif from "./OneNotif";
import PlusBtn from "./PlusBtn";
import { LoadingPage } from "../../globalElements/LoadingPage";


export default function ManyNotifs() {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [dataNotifs, setDataNotifs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(`${localhost}/notifs`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        setDataNotifs(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.error('Error'));
  }, []);

  return (
    <div className="md:px-16 px-3 min-h-screen">
      <div className="flex justify-between my-5">
        <p className="font-medium items-center text-3xl">Intraday updates</p>
        <PlusBtn />
      </div>

      {isLoading ?
        (<LoadingPage />)
        : (<div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 my-5">
          {dataNotifs.map((data, index) => (
            <OneNotif key={index} data={data} />
          ))}
        </div>
        )}

    </div>
  )
}