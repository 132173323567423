import axios from "axios";

export const clearExpiredSubscriptions = async () => {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  console.log("clearExpiredSubscriptions");
  
  axios.get(`${localhost}/clearexpiredsubscriptions`)
    .then(() => {
      axios.get(`${localhost}/user`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res)=>{
        localStorage.setItem('role', res.data.role);
        localStorage.setItem('myoffer', res.data.myoffer);
      }).catch((err)=>console.log('err'));
    })
    .catch((err) => console.error('Error'));
}