import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { DoneToast, ErrToast } from "../globalElements/AllToasts";
import ModeSwitcher from "../globalElements/ModeSwitcher";

export default function SignUp() {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const signup = async (e) => {
    e.preventDefault();
    const userData = { name, email, phone, password };
    axios.post(`${localhost}/register`, userData)
      .then((response) => {
        toast(<DoneToast text="signed up successfully" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          })
        setTimeout(() => { navigate("/signin") }, 2000);
      })
      .catch((err) => {
        toast(<ErrToast text="There is a problem!" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        console.error('Error');
      });
  }

  return (
    <section className="bg-darkblue-100 min-h-screen pb-3 dark:bg-dark">
      <Toaster />
      <div className="flex justify-end w-full p-5">
        <ModeSwitcher />
      </div>
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="wow fadeInUp relative mx-auto max-w-[525px] overflow-hidden rounded-lg bg-white dark:bg-dark-2 py-14 px-8 text-center sm:px-12 md:px-[60px]" data-wow-delay=".15s">
              <div className="mb-10 text-center">
                <a href="/" className="mx-auto inline-block max-w-[160px]">
                  <img src={`${process.env.PUBLIC_URL}/items/images/logo/logo-dark.png`} alt="logo" className="dark:hidden" />
                  <img src={`${process.env.PUBLIC_URL}/items/images/logo/logo.png`} alt="logo" className="hidden dark:block" />
                </a>
                <ul className="flex items-center justify-center mt-3 gap-[10px]">
                  <li>
                    <a href="/signin" className="flex items-center gap-[10px] text-base font-medium text-body-color">
                      Sign In
                    </a>
                  </li>
                  <li>
                    <a href="/signup" className="flex items-center gap-[10px] text-base font-medium text-dark dark:text-white">
                      <span className="text-body-color dark:text-dark-6"> / </span>
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
              <form onSubmit={signup}>
                <div className="mb-[22px]">
                  <input type="text" placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)}
                    className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-darkblue-100 dark:focus:border-darkblue-100 focus-visible:shadow-none" />
                </div>
                <div className="mb-[22px]">
                  <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-darkblue-100 dark:focus:border-darkblue-100 focus-visible:shadow-none" />
                </div>
                <div className="mb-[22px]">
                  <PhoneInput placeholder="Phone" value={phone} onChange={setPhone}
                    className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-darkblue-100 dark:focus:border-darkblue-100 focus-visible:shadow-none"
                  />
                </div>
                <div className="mb-[22px]">
                  <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke dark:border-dark-3 text-body-color dark:text-dark-6 placeholder:text-dark-6 focus:border-darkblue-100 dark:focus:border-darkblue-100 focus-visible:shadow-none" />
                </div>
                <div className="mb-9">
                  <input type="submit" value="Sign Up" className="w-full px-5 py-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-darkblue-100 bg-darkblue-100 hover:bg-blue-dark" />
                </div>
              </form>
              <p className="text-base text-body-secondary">
                Already a member ?
                <a href="/signin" className="text-darkblue-100 hover:underline">
                  Sign in
                </a>
              </p>

              <div>
                <span className="absolute top-1 right-1">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1.39737" cy="38.6026" r="1.39737" transform="rotate(-90 1.39737 38.6026)" fill="#3056D3" />
                    <circle cx="1.39737" cy="1.99122" r="1.39737" transform="rotate(-90 1.39737 1.99122)" fill="#3056D3" />
                    <circle cx="13.6943" cy="38.6026" r="1.39737" transform="rotate(-90 13.6943 38.6026)" fill="#3056D3" />
                    <circle cx="13.6943" cy="1.99122" r="1.39737" transform="rotate(-90 13.6943 1.99122)" fill="#3056D3" />
                    <circle cx="25.9911" cy="38.6026" r="1.39737" transform="rotate(-90 25.9911 38.6026)" fill="#3056D3" />
                    <circle cx="25.9911" cy="1.99122" r="1.39737" transform="rotate(-90 25.9911 1.99122)" fill="#3056D3" />
                    <circle cx="38.288" cy="38.6026" r="1.39737" transform="rotate(-90 38.288 38.6026)" fill="#3056D3" />
                    <circle cx="38.288" cy="1.99122" r="1.39737" transform="rotate(-90 38.288 1.99122)" fill="#3056D3" />
                    <circle cx="1.39737" cy="26.3057" r="1.39737" transform="rotate(-90 1.39737 26.3057)" fill="#3056D3" />
                    <circle cx="13.6943" cy="26.3057" r="1.39737" transform="rotate(-90 13.6943 26.3057)" fill="#3056D3" />
                    <circle cx="25.9911" cy="26.3057" r="1.39737" transform="rotate(-90 25.9911 26.3057)" fill="#3056D3" />
                    <circle cx="38.288" cy="26.3057" r="1.39737" transform="rotate(-90 38.288 26.3057)" fill="#3056D3" />
                    <circle cx="1.39737" cy="14.0086" r="1.39737" transform="rotate(-90 1.39737 14.0086)" fill="#3056D3" />
                    <circle cx="13.6943" cy="14.0086" r="1.39737" transform="rotate(-90 13.6943 14.0086)" fill="#3056D3" />
                    <circle cx="25.9911" cy="14.0086" r="1.39737" transform="rotate(-90 25.9911 14.0086)" fill="#3056D3" />
                    <circle cx="38.288" cy="14.0086" r="1.39737" transform="rotate(-90 38.288 14.0086)" fill="#3056D3" />
                  </svg>
                </span>
                <span className="absolute left-1 bottom-1">
                  <svg width="29" height="40" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2.288" cy="25.9912" r="1.39737" transform="rotate(-90 2.288 25.9912)" fill="#3056D3" />
                    <circle cx="14.5849" cy="25.9911" r="1.39737" transform="rotate(-90 14.5849 25.9911)" fill="#3056D3" />
                    <circle cx="26.7216" cy="25.9911" r="1.39737" transform="rotate(-90 26.7216 25.9911)" fill="#3056D3" />
                    <circle cx="2.288" cy="13.6944" r="1.39737" transform="rotate(-90 2.288 13.6944)" fill="#3056D3" />
                    <circle cx="14.5849" cy="13.6943" r="1.39737" transform="rotate(-90 14.5849 13.6943)" fill="#3056D3" />
                    <circle cx="26.7216" cy="13.6943" r="1.39737" transform="rotate(-90 26.7216 13.6943)" fill="#3056D3" />
                    <circle cx="2.288" cy="38.0087" r="1.39737" transform="rotate(-90 2.288 38.0087)" fill="#3056D3" />
                    <circle cx="2.288" cy="1.39739" r="1.39737" transform="rotate(-90 2.288 1.39739)" fill="#3056D3" />
                    <circle cx="14.5849" cy="38.0089" r="1.39737" transform="rotate(-90 14.5849 38.0089)" fill="#3056D3" />
                    <circle cx="26.7216" cy="38.0089" r="1.39737" transform="rotate(-90 26.7216 38.0089)" fill="#3056D3" />
                    <circle cx="14.5849" cy="1.39761" r="1.39737" transform="rotate(-90 14.5849 1.39761)" fill="#3056D3" />
                    <circle cx="26.7216" cy="1.39761" r="1.39737" transform="rotate(-90 26.7216 1.39761)" fill="#3056D3" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}