import articles from "../Blog/articles.json";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

export default function OurBlogs() {
  const data = articles.slice(-6).reverse();

  return (
    <section id="OurBlog" className="bg-white py-10 dark:bg-gray-900">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap justify-center">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[485px] text-center">
              <span className="mb-2 block text-lg font-semibold text-darkblue-100">Our Blogs</span>
              <h2 className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]">Our Recent Articles</h2>
              <p className="text-base text-body-color dark:text-dark-6">Access invaluable insights with our blog, offering free analysis to empower your financial decisions and navigate markets with confidence.</p>
            </div>
          </div>
        </div>
        <div className="-mx-4 grid grid-cols-2 lg:grid-cols-3 gap-4 ">
          {
            data.map((item, index) => (
              <div key={index} className="w-full rounded-lg px-4">
                <div className="wow fadeInUp group mb-10" data-wow-delay=".15s">
                  <div>
                    <div className="rounded overflow-hidden mb-8 aspect-w-16 aspect-h-9">
                      {item.images ? <a href={`/article/${item.id}`}><img src={`${process.env.PUBLIC_URL}/items/blog/${item.images[0].imgFile}`} className="rounded-lg border border-darkblue-100 hover:border-darkblue-200 object-cover w-full h-full mt-3" alt="Blog page" onContextMenu={(e) => e.preventDefault()} /></a> : null}
                    </div>

                    <span className="mb-6 inline-block rounded-[5px] bg-darkblue-100 px-4 py-0.5 text-center text-xs font-medium leading-loose text-white">
                      {item.date}
                    </span>
                    <h3>
                      <a href={`/article/${item.id}`} className="mb-4 inline-block text-xl font-semibold text-dark hover:text-darkblue-100 dark:text-white dark:hover:text-darkblue-100 sm:text-2xl lg:text-xl xl:text-2xl">
                        {item.title}
                      </a>
                    </h3>
                    <p className="max-w-[370px] text-base text-body-color dark:text-dark-6">
                      {`${item.text.substring(0, 85)}...`}
                    </p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="w-full">
        <a href="/articles" className="flex w-60 justify-center m-auto rounded-md p-3 mt-6 text-base font-medium text-darkblue-100 dark:text-white dark:hover:text-gray-300 transition hover:text-blue-800">
          Show More
          <ChevronRightIcon className="h-6 pt-0.5 ml-1 w-auto text-darkblue-100 dark:text-white dark:hover:text-gray-300 hover:text-blue-800" />
        </a>
      </div>
    </section>
  )
}