import Footer from "../../globalElements/Footer";
import AdmNavbar from "../../Admin/AdmNavbar";
import ManyNotifs from "./ManyNotifs";

export default function AdmNotifs() {
  return (
    <section className="text-gray-800  bg-white dark:bg-gray-900 dark:text-white">
      <AdmNavbar />
      <ManyNotifs />
      <Footer />
    </section>
  )
}