import { CircularProgress } from "react-cssfx-loading";

export const LoadingPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen w-full bg-white dark:bg-gray-900">
      <div>
        <CircularProgress color="#2563eb" width="60px" height="60px" duration="2s" />
      </div>
    </div>
  )
}

export const LoadingPage1 = () => {
  return (
    <div className={`flex items-center min-h-[30rem] justify-center w-full bg-gray-50 dark:bg-gray-900`}>
      <div>
        <CircularProgress color="#2563eb" width="60px" height="60px" duration="2s" />
      </div>
    </div>
  )
}
export const LoadingPage2 = ({height}) => {
  return (
    <div className={`flex items-center h-min-[${height}] justify-center w-full bg-white dark:bg-gray-900`}>
      <div>
        <CircularProgress color="#2563eb" width="60px" height="60px" duration="2s" />
      </div>
    </div>
  )
}
