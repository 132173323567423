
export default function Disclaimers() {
  return (
    <div className="bg-white text-gray-900 dark:bg-gray-900 dark:text-white px-10 py-16">
      <p className="mb-3 text-lg">IMPORTANT DISCLAIMERS</p>
      <p>
        The content on the website is provided for search, educational, assisting traders to make independent investment decisions and doesn't constitute investment advice. Any opinions, news, research, analyses, prices, or other information contained on this Website are provided as general market information and shouldn't be read as any recommendation or advice. Opinions, market data, or any other content is subject to change at any time without notice.The data contained in this website is not necessarily real-time nor accurate. It's not necessarily provided by any market or exchange, but may be provided by market makers, so prices may differ from the actual price at any given market.Elliott Wave Monitor and any provider of the data contained in this website will not accept liability for any loss or damage as a result of your trading, which may arise directly or indirectly from use of or reliance on such information.

        <br />
        <br />

        Trading foreign exchange on margin carries a high level of risk and may not be suitable to all investors.Before trading in financial instrument, you should be fully informed of the risks and costs associated with trading the financial markets and you should carefully consider your investment objectives, level of experience, risk appetite, and seek professional advice where needed. We don't recommend the use of technical analysis as a sole means of trading decisions. We advice you to perform your own research and to understand how these financial instruments work.
      </p>
      <p className="mt-3 text-sm">© {new Date().getFullYear()} ewm™. All Rights Reserved.</p>
    </div>
  )
}