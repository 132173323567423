import articles from "./articles.json";
import Footer from "../globalElements/Footer";
import Navbar from "../globalElements/Navbar";
import SecondPage from "./SecondPage";

export default function BlogPage() {
  const lastFour = articles.slice(-4);
  const lastThree = lastFour.slice(0, 3);
  const lastOne = lastFour[3];
  const allData = [...articles].reverse();

  return (
    <section className="text-gray-800 bg-white dark:bg-gray-900 dark:text-white">
      <Navbar />
      <div className="container grid grid-cols-12 mx-auto pt-[8rem] pb-8">
        <div className="flex flex-col justify-center col-span-12 align-middle dark:bg-gray-300 bg-no-repeat bg-cover lg:col-span-6 lg:h-auto"
          style={{ backgroundImage: `url(${lastOne.images ? `${process.env.PUBLIC_URL}/items/blog/${lastOne.images[0].imgFile}` : null})`, backgroundPosition: 'center center', backgroundBlendMode: 'multiply', backgroundSize: 'cover' }}>
          <div className="backdrop-blur-md flex items-center w-full h-full">
          <div className="flex flex-col p-8 items-center py-12 text-center dark:text-gray-800">
            <span>{lastOne.date}</span>
            <a href={`/article/${lastOne.id}`}>
              <h1 className="py-4 text-5xl hover:text-darkblue-100 font-bold">{lastOne.title}</h1>
            </a>
            <p className="pb-6">{`${lastOne.text.substring(0, 85)}...`}</p>
            <a href={`/article/${lastOne.id}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-7 h-7 hover:text-darkblue-100">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </a>
          </div>
          </div>
        </div>
        <div className="flex flex-col col-span-12 p-6 divide-y lg:col-span-6 lg:p-10 dark:divide-gray-300">
          {
            lastThree.map((item, index) => (
              <div key={index} className="pt-6 pb-4 space-y-2">
                <span>{item.date}</span>
                <a href={`/article/${item.id}`}>
                  <h1 className="text-3xl font-bold hover:text-darkblue-100">{item.title}</h1>
                </a>
                <p>{`${item.text.substring(0, 85)}...`}</p>
                <a href={`/article/${item.id}`} rel="noopener noreferrer" className="inline-flex items-center py-2 space-x-2 text-sm hover:text-darkblue-100 dark:text-violet-600">
                  <span>Read more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  </svg>
                </a>
              </div>
            ))
          }
        </div>
      </div>

      <SecondPage data={allData} />
      <Footer />
    </section>
  )
}